import TrailsService from "../services/trails.service"

export default {
    namespaced: true,
    state: {
        trailList:[],
        trail:null,
        trailPointList:[],
        trailPoint:null,
        page:'list'
    },
    getters:{

    },
    actions: {
        async getTrailList({commit},payload){
            const data = await TrailsService.getTrails(payload);
            commit('setTrailList',data);
        },
        
        async getTrail({commit},trail_id){
            const data = await TrailsService.getTrail(trail_id);
            commit('setTrail',data);
        },
        async getTrailPointsList({commit},trail_id){
            const data = await TrailsService.getTrailPoints(trail_id);
            commit('setTrailsPointsList',data);
        },
        async getTrailPoint({commit},trail_point_id){
            const data = await TrailsService.getTrail(trail_point_id);
            commit('setTrailPoint',data);
        },
        async createTrail({commit},payload){
            const data = await TrailsService.createTrail(payload);
            commit('createTrail',data);
        },
        async createTrailPoint({commit},payload){
            const data  = await TrailsService.createTrailPoint(payload);
            commit('createTrailPoint',data);
        },
        async editTrail({commit},payload){
            const data =  await TrailsService.editTrail(payload);
            commit('editTrail',data);
        },
        async editTrailPoint({commit},payload){
            const data = await TrailsService.editTrailPoint(payload);
            commit('editTrailPoint',data);
        },
        async deleteTrail({commit},trail_id){
            const data = await TrailsService.deleteTrail(trail_id);
            commit('deleteTrail',data);
        },
        async deleteTrailPoint({commit},trail_point_id){
            const data = await TrailsService.deleteTrailPoint(trail_point_id);
            commit('deleteTrailPoint',data);
        }
    },
    mutations: {
        setTrailList(state,list){
            for(let i = 0;i<list.length;i++){
                for(let index = 0;index<list[i].route_points.length;index++){
                    list[i].route_points[index].openInfo = false
                }
            }
            state.trailList = list;
        },
        setTrail(state,trail){
            state.trail = trail;
        },
        setTrailPointsList(state,trailPoints){
            state.getTrailPointsList = trailPoints;
        },
        setTrailPoint(state,trailPoint){
            state.trailPoint = trailPoint;
        },
        createTrail(state,trail){
            trail.route_points = [];
            state.trailList.push(trail);
        },
        createTrailPoint(state,trailPoint){
            const candidate = state.trailList.find(trail => trail.id === trailPoint.routes_id);
            if(candidate){
                const candidateIndex = state.trailList.indexOf(candidate);
                if(candidateIndex >= 0){
                    state.trailList[candidateIndex].route_points.push(trailPoint);
                }
            }
        },
        editTrail(state,editedTrail){
            const candidate = state.trailList.find(trail => trail.id === editedTrail.id);
            if(candidate){
                const candidateIndex = state.trailList.indexOf(candidate);
                if(candidateIndex >= 0){
                    state.trailList.splice(candidateIndex,1,editedTrail);
                }
            }
        },
        editTrailPoint(state,editedTrailPoint){
            const trailCandidate = state.trailList.find(trail => trail.id === editedTrailPoint.routes_id);
            if(trailCandidate){
                const trailCandidateIndex = state.trailList.indexOf(trailCandidate);
                if(trailCandidateIndex >= 0){
                    const trailPointCandidate = state.trailList[trailCandidateIndex].route_points.find(trailPoint => trailPoint.id === editedTrailPoint.id);
                    if(trailPointCandidate){
                        const trailPointCandidateIndex = state.trailList[trailCandidateIndex].route_points.indexOf(trailPointCandidate);
                        if(trailPointCandidateIndex >= 0){
                            state.trailList[trailCandidateIndex].route_points.splice(trailPointCandidateIndex,1,editedTrailPoint);
                        }
                    }
                }
            }
        },
        deleteTrail(state,deletedTrail){
            const candidate = state.trailList.find(trail => trail.id === deletedTrail.id);
            if(candidate){
                const candidateIndex = state.trailList.indexOf(candidate);
                if(candidateIndex >= 0){
                    state.trailList.splice(candidateIndex,1);
                }
            }
        },
        deleteTrailPoint(state,deletedTrailPoint){
            const trailCandidate = state.trailList.find(trail => trail.id === deletedTrailPoint.routes_id);
            if(trailCandidate){
                const trailCandidateIndex = state.trailList.indexOf(trailCandidate);
                if(trailCandidateIndex >= 0){
                    const trailPointCandidate = state.trailList[trailCandidateIndex].route_points.find(trailPoint => trailPoint.id === deletedTrailPoint.id);
                    if(trailPointCandidate){
                        const trailPointCandidateIndex = state.trailList[trailCandidateIndex].route_points.indexOf(trailPointCandidate);
                        if(trailPointCandidateIndex >= 0){
                            state.trailList[trailCandidateIndex].route_points.splice(trailPointCandidateIndex,1);
                        }
                    }
                }
            }
        },
        setPage(state,page){
            state.page = page;
        }
    },
}