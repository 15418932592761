<template>
  <div>
  <div v-if="$store.state.tab === 0 || $route.name !== 'Constructor'" style="background: #fff;position:relative;z-index:19;display: flex;justify-content: center;align-items: center;width: 100%;padding: 5px 0;box-shadow: 0 0 0 1px rgba(68,92,116,0.02),0 2px 8px rgba(57,76,96,0.15);">
    <div  @click="openMenu(menu)" :style="`color:${menu.active?'#007db5':'#7f8385'};`" style="flex:1;display: flex;flex-direction: column;align-items: center;justify-content: center;" v-for="menu in mobileMenu" :key="menu.name">
      <div><v-icon :color="`${menu.active?'#007db5':'#7f8385'}`">{{menu.icon}}</v-icon></div>
      <div style="font-size:12px;">{{menu.name}}</div>
    </div>
  </div>
    <div v-if="$store.state.tab === 1 && $route.name === 'Constructor'" style="display: flex;width: 100vw;overflow:hidden;padding: 5px;box-shadow: 0 0 0 1px rgba(68,92,116,0.02),0 2px 8px rgba(57,76,96,0.15);">
      <swiper ref="mySwiper" :options="swiperOptions" style="width: 100vw;">
        <swiper-slide style="width: 150px;"
            v-for="item in branding_pages"
            :key="item.id">
          <div @click="changeBrandingTab(item)" style="width:150px;height:40px;display: flex;align-items:center;justify-content: center;padding:10px;border: 1px solid #ccc;font-size:14px;"
               :class="{ active_tab: activeMenu(item.id) }">
            {{item.name}}
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div v-if="$store.state.tab === 2 && $route.name === 'Constructor'" style="display: flex;width: 100vw;overflow:hidden;padding: 5px;box-shadow: 0 0 0 1px rgba(68,92,116,0.02),0 2px 8px rgba(57,76,96,0.15);">
      <swiper ref="mySwiper" :options="swiperOptions" style="width: 100vw;">
        <swiper-slide style="width: 150px;">
          <div style="height:40px;display: flex;align-items:center;justify-content: center;padding:10px;font-size:14px;">
            <v-icon
                @click="addNewButton"
                style="
              cursor: pointer;
              padding: 10px;
              border: 1px solid rgb(238, 238, 238);
              border-radius: 50%;
              display: block;
              margin: 0 auto;
            "
            >mdi-plus</v-icon
            >
          </div>
        </swiper-slide>
        <swiper-slide style="width: 150px;">
          <div style="width:150px;font-weight:bold;height:40px;display: flex;align-items:center;justify-content: center;padding:10px;font-size:14px;">
            Show for all
          </div>
        </swiper-slide>
        <swiper-slide style="width: 150px;"
                      v-for="item in noAuthMenu"
                      :key="item.id">
          <div
              @click="changeType(item, item.type - 1)" style="width:150px;height:40px;display: flex;align-items:center;justify-content: center;padding:10px;border: 1px solid #ccc;font-size:14px;"

               :class="{ active_tab: activeMenuMenu(item) }">

            {{
              item.text[landing.current_lang].length > 15
                  ? item.text[landing.current_lang].slice(0, 12) + "..."
                  : item.text[landing.current_lang]
            }}
          </div>
        </swiper-slide>
        <swiper-slide style="width: 150px;">
          <div style="width:150px;font-weight:bold;height:40px;display: flex;align-items:center;justify-content: center;padding:10px;font-size:14px;">
            Authorizated
          </div>
        </swiper-slide>
        <swiper-slide style="width: 150px;"
                      v-for="item in authMenu"
                      :key="item.id">
          <div
              @click="changeType(item, item.type - 1)" style="width:150px;height:40px;display: flex;align-items:center;justify-content: center;padding:10px;border: 1px solid #ccc;font-size:14px;"

              :class="{ active_tab: activeMenuMenu(item) }">

            {{
              item.text[landing.current_lang].length > 15
                  ? item.text[landing.current_lang].slice(0, 12) + "..."
                  : item.text[landing.current_lang]
            }}
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div style="position:fixed;top:0;left:0;" v-if="showMenu">
      <Sidebar style="background: #fff;padding-bottom: 50px;" @closeSidebar="showMenu = false"/>
    </div>
  </div>
</template>

<script>
  import Sidebar from "../Sidebar";
  import {Swiper,SwiperSlide} from "vue-awesome-swiper";
  import "swiper/modules/pagination/pagination.min.css";
  import SwiperCore, { Pagination } from "swiper";
  SwiperCore.use([Pagination]);
  import {mapMutations, mapState} from 'vuex';
  import "swiper/swiper-bundle.css";
  import {createMenuItem} from "@/store/landing";

  export default {
    name: "MobileMenu",
    components: {Sidebar,Swiper,SwiperSlide},
    created() {
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    data(){
      return{
        swiperOptions:{
          slidesPerView: 'auto',
          spaceBetween: 10,
        },
        branding_pages:[
          {
            id:15,page:'',name:'Menu Styles'
          },
          {
            id:0,page:'home',name:'Header',
          },
          {
            id:1,page:'home',name:'Logo',
          },
          {
            id:2,page:'home',name:"Text Logo",
          },
          {
            id:3,page:'home',name:'Footer',
          },
          {
            id:4,page:'home',name:'Footer Text',
          },
          {
            id:5,page:'home',name:'Background',
          },
          {
            id:6,page:'lining',name:'Lining',
          },
          {
            id:7,page:'loader',name:'Loader',
          },
          {
            id:8,page:'home',name:'Modal Banner',
          },
          {
            id:9,page:'home',name:'Bottom Banner',
          },
          {
            id:10,page:'intro',name:'Intro Banner',
          },
          {
            id:11,page:'home',name:'Slider',
          },
          {
            id:12,page:'home',name:'Bottom Menu',
          },
          {
            id:13,page:'topMenu',name:"Top Menu",
          },
          {
            id:14,page:'home',name:'Socials'
          },
          {
            id:16,page:'sidebar',name:'Sidebar'
          },
          {
            id:17,page:'home',name:'Events'
          },
          {
            id:18,page:'home',name:"Templates"
          }
        ],
        isMobile:false,
        showMenu:false,
        mobileMenu:[
          {
            id:0,
            name:"Main",
            icon:"mdi-home",
            link:"welcome",
            active:true
          },
          {
            id:1,
            name:"My projects",
            icon:"mdi-folder",
            link:"my-objects",
            active:false
          },
          {
            id:2,
            name:"Menu",
            icon:"mdi-menu",
            link:"",
            active:false
          }
        ]
      }
    },
    watch:{
      '$route.name'(val){
        for(let i = 0;i<this.mobileMenu.length;i++){
          this.mobileMenu[i].active = false;
        }
        if(val === 'objects'){
          this.mobileMenu[1].active = true;
        }
        if(val === 'welcome'){
          this.mobileMenu[0].active = true;
        }
      }
    },
    computed:{
      ...mapState(['landing']),
      ...mapState("menu", ["selectedMenuItem"]),
      ...mapState("landing", [
        "menu"
      ]),
      authMenu() {
        return this.$store.state.menu.list.filter((item) => item.auth_switch);
      },
      noAuthMenu() {
        return this.$store.state.menu.list.filter((item) => !item.auth_switch);
      },
    },
    methods:{
      ...mapMutations("menu", ["setSelectedMenuItem", "setMenuList"]),
      activeMenuMenu(menu) {
        return menu.id === this.selectedMenuItem?.id;
      },
      changeType(content, ind) {
        if (content.page === "reviews") {
          this.landing.reviews.tab = 0;
          this.$store.commit("setPage",'reviews');
        }
        this.menu.active_tab = 1;
        content.type = ind + 1;
        this.active_menu_tab = ind + 1;
        if (content.type === 2 || content.type === 3) {
          this.$store.commit("setPage", "home");
        }
        else if (content.type === 5) {
          let item = this.$store.state.menu.list.find((x) => x.id === content.id);
          content.content[this.landing.current_lang][4].content.menu.map((x) => {
            x.hidden = true;
            x.flipped = false;
          });
          const candidate = content.content[
              this.landing.current_lang
              ][4].content.menu.find((x) => x.hidden === false);
          if (candidate) {
            if (candidate.flipped) {
              this.flipped = true;
              this.selectedMenu = candidate;
              this.$store.commit("setPage", item.page + "/" + candidate.id);
            } else {
              this.$store.commit("setPage", item.page);
            }
          } else {
            this.$store.commit("setPage", item.page);
          }
        } else {
          this.$store.commit("setPage", content.page);
        }
        if (!this.selectedMenuItem) {
          this.flipped = false;
          this.setSelectedMenuItem(JSON.parse(JSON.stringify(content)));
        } else {
          this.flipped = false;
          const candidate = this.$store.state.menu.list.find(
              (item) => item.id === this.selectedMenuItem.id
          );
          if (candidate) {
            const candidateIndex = this.$store.state.menu.list.indexOf(candidate);
            if (candidateIndex >= 0) {
              this.$store.state.menu.list.splice(
                  candidateIndex,
                  1,
                  JSON.parse(JSON.stringify(this.selectedMenuItem))
              );
            }
          }
          this.setSelectedMenuItem(JSON.parse(JSON.stringify(content)));
          this.forceRerender();
        }
      },
      forceRerender() {
        // Removing my-component from the DOM
        this.$store.state.renderComponent = false;

        this.$nextTick(() => {
          // Adding the component back in
          this.$store.state.renderComponent = true;
        });
      },
      addNewButton() {
        const button = createMenuItem(this.landing.menu.list.length);
        this.landing.advanced.selected_langs.forEach((lang) => {
          if (!button.content[lang]) {
            button.content[lang] = button.content["en"];
          }
        });
        this.landing.menu.list.push(button);
      },
      openStyle() {
        this.$store.commit("mainPageStyles/setGlobalRadius", this.landing.menu.radius);
        this.$store.commit(
            "mainPageStyles/setGlobalShadowSize",
            this.landing.menu.shadowSize
        );
        this.$store.commit(
            "mainPageStyles/setGlobalShadowColor",
            this.landing.menu.shadowColor
        );
        this.$store.commit(
            "mainPageStyles/setGlobalIconSize",
            this.landing.menu.icon_size
        );
        this.$store.commit("mainPageStyles/setList", JSON.parse(JSON.stringify(this.landing.menu.list)));
        this.$store.commit("setPage", "menuStyles");
        this.$store.commit('mainPageStyles/setStylesOpen',true);
      },
      activeMenu(id){
        return id === this.$store.state.active_branding_id
      },
      changeBrandingTab(item){
        this.landing.branding.active_tab = item.id;
        this.$store.state.active_branding_id = item.id;
        if(item.id === 15){
          this.openStyle()
        }else{
          this.$store.commit('setPage',item.page);
        }
      },
      openMenu(menu){
        if(menu.id === 2){
          this.showMenu = !this.showMenu;
        }
        if(menu.id === 1){
          this.$router.push('/objects');
        }
        if(menu.id === 0){
          this.$router.push(`/welcome`);
        }
        for(let i = 0;i<this.mobileMenu.length;i++){
          this.mobileMenu[i].active = false;
        }
        menu.active = !menu.active;
      }
    }
  }
</script>

<style lang="scss">

</style>
