
export default {
    namespaced:true,
    state:{
        order_form:null,
        order:null
    },
    getters:{

    },
    mutations:{
        setOrderForm(state,order_form){
            state.order_form = order_form;
        },
        setOrder(state,order){
            state.order = order;
        }
    },
    actions:{

    }
}