import Vue from 'vue';

Vue.directive('external-link', {
  update: function(el) {
    const href = el.getAttribute("href");
    
    if (href && href.indexOf("http://") == -1 && href.indexOf("https://") == -1) {
        el.setAttribute("href", "http://" + href);
    }
  },
});

export default {}