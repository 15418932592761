import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        list: []
    },
    mutations: {
        setList(state, list) {
            state.list = list;
        },
    },
    actions: {
        getList({commit}) {
            return new Promise((resolve, reject) => {
                Vue.prototype.$axios
                    .get('get-help')
                    .then(response => {
                        // console.log(response)
                        commit('setList', response.data.data)
                        resolve(response.data.data);
                    })
                    .catch(error => {
                        console.log(error.response)
                        reject(error.response)
                    });
            });
        }
    },
}