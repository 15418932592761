import $axios from "@/plugins/axios"

const PointService = {
    async editPointCategory(pointcategory){
        try{
            const {data} = await $axios.put(`point-category/${pointcategory.id}`,pointcategory);
            return data;
        }catch (e){
            return e;
        }
    },
    async editPoint(point){
        try{
            const {data} = await $axios.put(`point/${point.id}`,point);
            return data;
        }catch (e){
            return e;
        }
    }
}
export default PointService