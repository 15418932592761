import ProductsService from "@/services/products.service";

export default {
  namespaced: true,
  state: {
    categories: [],
    pageProduct: "categoryList",
  },
  mutations: {
    setCategories(state, categories) {
      state.categories = categories;
    },
    setPageProducts(state, page) {
      state.pageProduct = page;
    },
    editCategory(state,category){
      const candidate = state.categories.find(x => x.id === category.id);
      if(candidate){
        const candidateIndex = state.categories.indexOf(candidate);
        if(candidateIndex>=0){
          state.categories.splice(candidateIndex,1,category);
        }
      }
    },
    editSubCategory(state,subcategory){
      const candidateCategory = state.categories.find(x => x.id === subcategory.product_category_id);
      if(candidateCategory){
        const candidateCategoryIndex = state.categories.indexOf(candidateCategory);
        if(candidateCategoryIndex>=0){
          const candidateSubCategory = state.categories[candidateCategoryIndex].sub_categories.find(x => x.id === subcategory.id);
          if(candidateSubCategory){
            const candidateSubCategoryIndex = state.categories[candidateCategoryIndex].sub_categories.indexOf(candidateSubCategory);
            if(candidateSubCategoryIndex>=0){
              state.categories[candidateCategoryIndex].sub_categories[candidateSubCategoryIndex].splice(candidateSubCategoryIndex,1,subcategory);
            }
          }
        }
      }
    },
    editProduct(state,product){
      const candidateCategory = state.categories.find(x => x.id === product.product_category_id);
      if(candidateCategory){
        const candidateCategoryIndex = state.categories.indexOf(candidateCategory);
        if(candidateCategoryIndex>=0){
          if(product.product_sub_category_id){
            const candidateSubCategory = state.categories[candidateCategoryIndex].sub_categories.find(x => x.id === product.product_sub_category_id);
            if(candidateSubCategory){
              const candidateSubCategoryIndex = state.categories[candidateCategoryIndex].sub_categories.indexOf(candidateSubCategory);
              if(candidateSubCategoryIndex>=0){
                const candidateProduct = state.categories[candidateCategoryIndex].sub_categories[candidateSubCategoryIndex].products.find(x => x.id === product.id);
                if(candidateProduct){
                  const candidateProductIndex = state.categories[candidateCategoryIndex].sub_categories[candidateSubCategoryIndex].products.indexOf(candidateProduct);
                  if(candidateProductIndex>=0){
                    state.categories[candidateCategoryIndex].sub_categories[candidateSubCategoryIndex].products.splice(candidateProductIndex,1,product);
                  }
                }
              }
            }
          }else{
            const candidateProduct = state.categories[candidateCategoryIndex].products.find(x => x.id === product.id);
            if(candidateProduct){
              const candidateProductIndex = state.categories[candidateCategoryIndex].products.indexOf(candidateProduct);
              if(candidateProductIndex>=0){
                state.categories[candidateCategoryIndex].products.splice(candidateProductIndex,1,product);
              }
            }
          }
        }
      }
    }
  },
  getters: {},
  actions: {
    async getCategoriesList({ commit }, payload) {
      const data = await ProductsService.getCategoriesList(
        payload.hotel_id,
        payload.menu_id
      );
      commit("setCategories", data);
    },
    async editCategory({commit},category){
      try{
        const data = await ProductsService.editCategory(category);
        commit('editCategory',data);
      }catch(e){
        return e;
      }
    },
    async editSubCategory({commit},subcategory){
      try{
        const data = await ProductsService.editSubCategory(subcategory);
        commit('editSubcategory',data);
      }catch(e){
        return e;
      }
    },
    async editProduct({commit},product){
      try{
        const data = await ProductsService.editProduct(product);
        commit('editProduct',data);
      }catch (e){
        return e;
      }
    }
  },
};
