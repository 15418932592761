import $axios from "@/plugins/axios"

const RealEstateService = {
  async getRealEstates(payload){
    try{
      const {data} =  await $axios.get(`real-estates/${payload.hotel_id}/${payload.menu_id}`);
      return data;
    }catch(e){
      return e;
    }
  },
  async getRealEstate(id){
    try{
      const {data} = await $axios.get(`real-estate/${id}`);
      return data;
    } catch(e){
      return e;
    }
  },
  async postRealEstate(realEstate){
    try{
      const {data} = await $axios.post(`real-estate`,realEstate);
      return data;
    }catch (e) {
      return e;
    }
  },
  async putRealEstate(realEstate){
    try{
      const {data} = await $axios.put(`real-estate/${realEstate.id}`,realEstate);
      return data;
    } catch (e) {
      return e;
    }
  },
  async deleteRealEstate(id){
    try{
      const {data} = await $axios.delete(`real-estate/${id}`);
      return data;
    }catch (e) {
      return e;
    }
  }

};
export default RealEstateService;
