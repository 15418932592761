import Vue from 'vue'
import Vuex from 'vuex'
import help from './help'
import landing from './landing'
import objects from './objects'
import polls from './polls'
import stickers from './stickers'
import events from "./events"
import rooms from "./rooms"
import points from "./points"
import loader from "./loader"
import products from "./products"
import trails from "./trails"
import quests from './quests'
import branding from './branding'
import advanced from './advanced'
import menu from './menu'
import orders from './orders'
import reviews from './reviews'
import banners from './banners'
import radio from './radio'
import mainPageStyles from "./mainPageStyles";
import intro from "./intro"
import bottomBanner from "./bottomBanner"
import catalog from './catalog'
import realEstate from "./realEstate";
import segment from "./segment";
import segmentProducts from "./segmentProducts";
import passcode from "./passcode";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        settings:null,
        isMobile:false,
        selectBrandingMenu:null,
      active_branding_id:15,
      selectedPage:'start',
        statistics:{
          date_start:null,
          date_end:null,
          object_id:null
        },
        touristListings:[],
        touristListingDetail:{
          group:null,
          item:null
        },
        touristListingPage:null,
        renderComponent:true,
        telegramUsers:[],
        tab:0,
        orderSuccessPageShow:'items',
        locale: localStorage.getItem('current_locale') || 'en',
        page: 'home',
        loadingHotelStarted:false,
        loadingPage:null,
        loadingProgress:false,
        menu_active_tab: undefined,
        progress:0,
        dynamicFlipped:false,
        dynamicType:0,
        dynamicIndex:null,
        hotel_lang: localStorage.getItem('hotel_lang') || null,
        langs: [
            { value: "en", name: "English", flag: "https://guest.mobi/3x2/US.svg" },
            { value: "es", name: "Spanish", flag: "https://guest.mobi/3x2/ES.svg" },
            { value: "fr", name: "French", flag: "https://guest.mobi/3x2/FR.svg" },
            { value: "hr", name: "Croation", flag: "https://guest.mobi/3x2/HR.svg" },
            { value: "de", name: "Deutsch", flag: "https://guest.mobi/3x2/DE.svg" },
            { value: "it", name: "Italian", flag: "https://guest.mobi/3x2/IT.svg" },
            { value: "pl", name: "Polish", flag: "https://guest.mobi/3x2/PL.svg" },
            { value: "pt", name: "Portuguese", flag: "https://guest.mobi/3x2/PT.svg" },
            { value: "ro", name: "Romanian", flag: "https://guest.mobi/3x2/RO.svg" },
            { value: "ru", name: "Russian", flag: "https://guest.mobi/3x2/RU.svg" },
            { value: "si", name: "Slovenian", flag: "https://guest.mobi/3x2/SI.svg" },
            { value: "zh", name: "Chinese", flag: "https://guest.mobi/3x2/CN.svg" },
            { value: "cs", name: "Czech", flag: "https://guest.mobi/3x2/CZ.svg" },
            { value: "bs", name: "Bosnian", flag: "https://guest.mobi/3x2/BA.svg" },
            { value: "sr", name: "Serbian", flag: "https://guest.mobi/3x2/RS.svg" },
            { value: "uk", name: "Ukrainian", flag: "https://guest.mobi/3x2/UA.svg" },
        ],
        selected_langs: ['en'],
        canvas: {
            view: 'mobile',
            layout: 'default',
            font_color: null,
            logo: null,
            title: null,
            header_image: null,
            footer_title: null,
            footer_text: null,
            bg_image: null,
            bg_color: null,
            is_bg: null,

            menu: null,
            socials: null,
            reviews: null,
            textReviews: null,
            is_show_lang: null,
        },
        landing: {},
        token: localStorage.getItem('token') || null,
        profile: {
          id:null,
          name:null,
          birthday:null,
          last_name:null,
          phone:null,
          email:null,
          avatar:null,
          timezone:null,
          is_manager:null,
          is_reception:null,
          is_agency:null,
          hotels_id:null,
        },
        listing: {
            page: null,
            index: null,
            listingGroup: null,
            subListing: null
        },
        hotel_user:null
    },
    mutations: {
        setLocale(state, locale) {
            state.locale = locale;
            localStorage.setItem('current_locale', locale);
        },

        setPage(state, page) {
            state.page = page;
            state.listing = {
                page: null,
                index: null,
                listingGroup: null,
                subListing: null
            };
        },
        setSubListingPageList(state, page) {
            state.listing = {
                page: page,
                index: null,
                listingGroup: null,
                subListing: null
            };
        },

        setMenuActiveTab(state, tab) {
            state.menu_active_tab = tab;
        },

        setListingPage(state, data) {
            state.listing = {
                page: data.page,
                listingGroup: data.listingGroup,
                index: data.index,
                subListing: null
            };
        },
        setSubListingPage(state, data) {
            state.listing = {
                page: data.page,
                listingGroup: data.listingGroup,
                index: null,
                subListing: data.subListing
            };
        },

        setAdvancedHotel(state,advanced){
            if(state.hotel){
                state.hotel.advanced = advanced;
            }else{
                state.hotel = {
                    advanced:advanced
                }
            }
        },
        setReviewsHotel(state,reviews){
            if(state.hotel){
                state.hotel.reviews = reviews;
            }else{
                state.hotel = {
                    reviews:reviews
                }
            }
        },
        setBrandingHotel(state,branding){
            if(state.hotel){
                state.hotel.branding = branding;
            }else{
                state.hotel = {
                    branding:branding
                }
            }
        },
        setMenuHotel(state,menu){
            if(state.hotel){
                state.hotel.menu = {
                    list:menu
                }
            }else{
                state.hotel = {
                    menu:{
                        list:menu
                    }
                }
            }
        },
        setOrderForm(state,form){
            if(state.hotel){
                state.hotel.order_form = form;
            }else{
                state.hotel = {
                    order_form:form
                }
            }
        },
        setOrder(state,form){
            if(state.hotel){
                state.hotel.order = form;
            }else{
                state.hotel = {
                    order:form
                }
            }
        },
        setMenuStylesHotel(state,styles){
            if(state.hotel){
                state.hotel.menu = {...state.hotel.menu,...styles}
            }else{
                state.hotel = {
                    menu: {
                        ...styles
                    }
                }
            }
        },
        setSocialsHotel(state,socials){
            if(state.hotel){
                state.hotel.socials = socials
            }else{
                state.hotel = {
                    socials:socials
                }
            }
        },
        setTariffHotel(state,tariff){
            if(state.hotel){
                state.hotel.tariff = tariff
            }else{
                state.hotel = {
                    tariff:tariff
                }
            }
        },
        setCanvas(state, canvas) {
            state.canvas.view = canvas.view;
            state.canvas.layout = canvas.layout;
            state.canvas.font_color = canvas.font_color;
            state.canvas.title = canvas.title;
            state.canvas.logo = canvas.logo;
            state.canvas.header_image = canvas.header_image;
            state.canvas.footer_title = canvas.footer_title;
            state.canvas.footer_text = canvas.footer_text;
            state.canvas.bg_image = canvas.bg_image;
            state.canvas.bg_color = canvas.bg_color;
            state.canvas.is_bg = canvas.is_bg;
        },
        setView(state, view) {
            state.canvas.view = view;
        },

        setLangs(state, langs) {
            state.selected_langs = langs;
        },

        setToken(state, token) {
            state.token = token;
            Vue.prototype.$axios.defaults.headers.common['Authorization'] = `Bearer  ${token}`;
            localStorage.setItem('token', token);
        },
        removeToken(state) {
            state.token = null;
            delete Vue.prototype.$axios.defaults.headers.common['Authorization'];
            localStorage.removeItem('token');
        },

        setReviews(state, reviews) {
            state.landing.reviews = reviews;
        },

        setAdvanced(state, advanced) {

            state.selected_langs = [];
            advanced.langs.forEach(lang => {
                if (lang.active) {
                    state.selected_langs.push(lang.value);
                }
            });

            state.landing.advanced = advanced;
        },

        setIsShowLang(state, is_show_lang) {
            state.canvas.is_show_lang = is_show_lang;
            state.landing.advanced.show_lang = is_show_lang;
        },

        setLanding(state, hotel) {
            state.hotel = hotel;
        },

        setProfile(state, profile) {
            state.profile = profile;
        },

        register(state) {

        }
    },
    actions: {
        async getLanging({ commit }, id) {
            return new Promise((resolve, reject) => {
                Vue.prototype.$axios
                    .get(`get-lending?hotel_id=${id}`)
                    .then(async (response) => {
                        var userLang = navigator.language || navigator.userLanguage;
                        let langs = [];
                        userLang = userLang.slice(0, 2);
                        response.data.advanced.defaults_langs.forEach(item => {
                            langs.push(item.value);
                        });

                        if (localStorage.getItem('hotel_lang')) {
                            if (langs.includes(localStorage.getItem('hotel_lang'))) {
                                Vue.prototype.$axios.defaults.headers['Accept-Language'] = localStorage.getItem('hotel_lang');
                                localStorage.setItem('hotel_lang', localStorage.getItem('hotel_lang'));
                            } else {
                                Vue.prototype.$axios.defaults.headers['Accept-Language'] = response.data.advanced.default_lang;
                                localStorage.setItem('hotel_lang', response.data.advanced.default_lang);
                            }
                        } else {
                            if (langs.includes(userLang)) {
                                Vue.prototype.$axios.defaults.headers['Accept-Language'] = userLang;
                                localStorage.setItem('hotel_lang', userLang);
                            } else {
                                Vue.prototype.$axios.defaults.headers['Accept-Language'] = response.data.advanced.default_lang;
                                localStorage.setItem('hotel_lang', response.data.advanced.default_lang);
                            }
                        }
                        commit('setLanding', response.data);
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error.response);
                    });
            });
        },
        getLandingByDomain({ commit }, domain) {
            return new Promise((resolve, reject) => {
                Vue.prototype.$axios
                    .get(`get-lending?domain=${domain}`)
                    .then(response => {
                        var userLang = navigator.language || navigator.userLanguage;
                        let langs = [];
                        userLang = userLang.slice(0, 2);
                        response.data.advanced.defaults_langs.forEach(item => {
                            langs.push(item.value);
                        });

                        if (localStorage.getItem('hotel_lang')) {
                            if (langs.includes(localStorage.getItem('hotel_lang'))) {
                                Vue.prototype.$axios.defaults.headers['Accept-Language'] = localStorage.getItem('hotel_lang');
                                localStorage.setItem('hotel_lang', localStorage.getItem('hotel_lang'));
                            } else {
                                Vue.prototype.$axios.defaults.headers['Accept-Language'] = response.data.advanced.default_lang;
                                localStorage.setItem('hotel_lang', response.data.advanced.default_lang);
                            }
                        } else {
                            if (langs.includes(userLang)) {
                                Vue.prototype.$axios.defaults.headers['Accept-Language'] = userLang;
                                localStorage.setItem('hotel_lang', userLang);
                            } else {
                                Vue.prototype.$axios.defaults.headers['Accept-Language'] = response.data.advanced.default_lang;
                                localStorage.setItem('hotel_lang', response.data.advanced.default_lang);
                            }
                        }

                        Vue.prototype.$axios.get(`get-lending?domain=${domain}`).then(response => {
                            commit('setLanding', response.data);
                            resolve(response.data);
                        });

                    })
                    .catch(error => {
                        reject(error.response);
                    });
            });
        },
        saveLanging({ state }, id) {
            Vue.prototype.$axios
                .post('edit-lending-settings', {
                    hotel_id: +id,
                    branding: state.landing.branding,
                    menu: state.landing.menu,
                    socials: state.landing.socials,
                    reviews: state.landing.reviews,
                    advanced: state.landing.advanced,
                    domain: state.landing.domain,
                    rooms: state.landing.rooms,
                    order: state.landing.order,
                    order_form: state.landing.order_form
                })
                .then(response => {

                })
                .catch(error => {

                });
        },

        register({ commit }, user) {
            return new Promise((resolve, reject) => {
                Vue.prototype.$axios
                    .post('register', user)
                    .then((response) => {
                        commit('register')
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error.response)
                    });
            });
        },
        verify({ commit }, token) {
            return new Promise((resolve, reject) => {
                Vue.prototype.$axios
                    .get('verify', {
                        params: {
                            token: token,
                        }
                    })
                    .then(response => {
                        commit('setToken', response.data.token);
                        resolve();
                    })
                    .catch(error => {
                        reject(error.response)
                    });
            });
        },
        login({ commit }, user) {
            return new Promise((resolve, reject) => {
                Vue.prototype.$axios
                    .post('login', user)
                    .then(response => {
                        commit('setToken', response.data.token);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error.response)
                    });
            });
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                commit('removeToken')
                resolve()
            })
        },
        getProfile({ commit }) {
            return new Promise((resolve, reject) => {
                Vue.prototype.$axios
                    .get('get-profile')
                    .then(response => {
                        commit('setProfile', response.data);
                        resolve(response);
                    })
                    .catch(error => {
                        localStorage.removeItem('token');
                        reject(error.response)
                    });
            });
        },
        saveProfile({ dispatch }, data) {
            let formData = new FormData();

            Object.keys(data).forEach(key => {
                if (data[key]) {
                    formData.append(key, data[key]);
                }
            })

            return new Promise((resolve, reject) => {
                Vue.prototype.$axios
                    .post(`edit-profile`, formData)
                    .then(response => {
                        dispatch('getProfile');
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error.response)
                    })
            });
        },
    },
    getters: {
        isAuth: state => !!state.token,
        menu_active_tab: state => state.menu_active_tab,
    },
    modules: {
        objects: objects,
        stickers: stickers,
        help: help,
        landing: landing,
        polls: polls,
        events:events,
        rooms:rooms,
        points: points,
        loader:loader,
        products:products,
        trails:trails,
        quests:quests,
        branding:branding,
        advanced:advanced,
        menu:menu,
        orders:orders,
        reviews:reviews,
        banners:banners,
        radio:radio,
        mainPageStyles:mainPageStyles,
        intro:intro,
        bottomBanner:bottomBanner,
        catalog:catalog,
        realEstate:realEstate,
        segments:segment,
        segmentProducts:segmentProducts,
        passcode:passcode
    }
})
