import $axios from "../plugins/axios";

const CatalogService = {
  async getCatalogCategories(payload) {
    try {
      const { data } = await $axios.get(
        `catalog-categories/${payload.hotel_id}/${payload.menu_id}`
      );
      return data;
    } catch (e) {
      return e;
    }
  },
  async getCatalogCategory(catalog_category_id){
    try{
      const {data} = await $axios.get(`catalog-category/${catalog_category_id}`);
      return data;
    }catch(e){
      return e;
    }
  },
  async postCatalogCategory(catalog_category){
    try{
      const {data} = await $axios.post(`catalog-category`,catalog_category);
      return data;
    }catch (e) {
      return e;
    }
  },
  async putCatalogCategory(catalog_category){
    try{
      const {data} = await $axios.put(`catalog-category/${catalog_category.id}`,catalog_category);
      return data;
    }catch (e) {
      return e;
    }
  },
  async deleteCatalogCategory(catalog_category_id){
    try{
      const {data} = await $axios.delete(`catalog-category/${catalog_category_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getCatalogs(catalog_category_id){
    try{
      const {data} = await $axios.get(`catalogs/${catalog_category_id}`);
      return data;
    }catch(e){
      return e;
    }
  },
  async getCatalog(catalog_id){
    try{
      const {data} = await $axios.get(`catalog/${catalog_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async postCatalog(catalog){
    try{
      const {data} = await $axios.post(`catalog`,catalog);
      return data;
    }catch (e) {
      return e;
    }
  },
  async putCatalog(catalog){
    try{
      const {data} = await $axios.put(`catalog/${catalog.id}`,catalog);
      return data;
    }catch (e) {
      return e;
    }
  },
  async deleteCatalog(catalog_id){
    try{
      const {data} = await $axios.delete(`catalog/${catalog_id}`);
      return data;
    }catch(e){
      return e;
    }
  }
};
export default CatalogService;
