export default {
  namespaced: true,
  state: {
    style: "tile",
    list: [],
    globalSettings: {
      radius: 10,
      icon_size: 32,
      shadow_color: "#000000",
      shadow_size: 3,
    },
    stylesOpen:false
  },
  mutations: {
    setStylesOpen(state,open){
      state.stylesOpen = open;
    },
    setStyle(state, style) {
      state.style = style;
    },
    setGlobalRadius(state, radius) {
      state.globalSettings.radius = radius;
    },
    setGlobalIconSize(state, size) {
      state.globalSettings.icon_size = size;
    },
    setGlobalShadowColor(state, color) {
      state.globalSettings.shadow_color = color;
    },
    setGlobalShadowSize(state, size) {
      state.globalSettings.shadow_size = size;
    },
    setList(state, list) {
      state.list = [];
      state.list = JSON.parse(JSON.stringify(list));
    },
  },
};
