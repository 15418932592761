import SegmentService from "../services/segment.service";

export default {
  namespaced:true,
  state:{
    segments:[],
    segment:null,
    error:null
  },
  getters:{

  },
  actions:{
    async getSegments({commit},hotel_id){
      try{
        const data = await SegmentService.getSegments(hotel_id);
        commit('setSegments',data);
      }catch (e) {
        commit('setError',e.message);
      }
    },
    async getSegment({commit},segment_id){
      try{
        const data = await SegmentService.getSegment(segment_id);
        commit('setSegment',data);
      }catch (e) {
        commit('setError',e.message);
      }
    },
    async postSegment({commit},segment){
      try{
        const data = await SegmentService.postSegment(segment);
        commit('postSegment',data);
        commit('setSegment',data);
      }catch (e) {
        commit('setError',e.message);
      }
    },
    async putSegment({commit},segment){
      try{
        const data = await SegmentService.putSegment(segment);
        commit('putSegment',data);
        commit('setSegment',data);
      }catch (e) {
        commit('setError',e.message);
      }
    },
    async deleteSegment({commit},segment_id){
      try{
        const data = await SegmentService.deleteSegment(segment_id);
        commit('deleteSegment',data);
        commit('setSegment',data);
      }catch (e) {
        commit('setError',e.message);
      }
    }
  },
  mutations:{
    setError(state,error){
      state.error = error;
    },
    setSegments(state,segments){
      state.segments = segments
    },
    setSegment(state,segment){
      state.segment = segment;
    },
    postSegment(state,segment){
      state.segments.push(segment);
    },
    putSegment(state,segment){
      const candidate = state.segments.find(x => x.id === segment.id);
      if(candidate){
        const candidateIndex = state.segments.indexOf(candidate);
        if(candidateIndex>=0){
          state.segments.splice(candidateIndex,1,segment);
        }
      }
    },
    deleteSegment(state,segment){
      const candidate = state.segments.find(x => x.id === segment.id);
      if(candidate){
        const candidateIndex = state.segments.indexOf(candidate);
        if(candidateIndex>=0){
          state.segments.splice(candidateIndex,1);
        }
      }
    }
  }
}
