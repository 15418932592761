import ModalBannersService from "@/services/modalBanners.service";

export default {
    namespaced:true,
    state:{
        bannerList:[],
        selectedBanner:null,
        error:null,
        bannerPreview:false,
        saveBanners:[],
    },
    getters:{

    },
    actions:{
        async getBannerList({commit},hotel_id){
            try{
                const data = await ModalBannersService.getBanners(hotel_id);
                commit('setBannerList',data);
            }catch (e){
                commit('setError',e);
            }
        },
        async getOneBanner({commit},banner_id){
            try{
                const data = await ModalBannersService.getOneBanner(banner_id);
                commit('setCurrentBanner',data);
            }catch (e){
                commit('setError',e);
            }
        },
        async createBanner({commit},banner){
            try{
                const data = await ModalBannersService.createBanner(banner);
                commit('createBanner',data);
            }catch (e){
                commit('setError',e);
            }
        },
        async editBanner({commit},banner){
            try{
                const data = await ModalBannersService.editBanner(banner);
                commit('editBanner',data);
            }catch (e){
                commit('setError',e);
            }
        },
        async deleteOneBanner({commit},banner_id){
            try{
                const data = await ModalBannersService.deleteBanner(banner_id);
                commit('deleteOneBanner',data);
            }catch (e){
                commit('setError',e);
            }
        }
    },
    mutations:{
        setCurrentBannerHotel(state,banner){
          state.selectedBanner = banner;
        },
        setBannerPreview(state,preview){
          state.bannerPreview = preview;
        },
        setBannerList(state,list){
            state.bannerList = list;
        },
        setCurrentBanner(state,payload){
            if(payload.prevBanner){
                const candidate = state.bannerList.find(x => x.id === payload.prevBanner.id);
                if(candidate){
                    const candidateIndex = state.bannerList.indexOf(candidate);
                    state.bannerList.splice(candidateIndex,1,payload.prevBanner);
                }
                const candidateForSave = state.saveBanners.find(x => x.id === payload.prevBanner.id);
                if(candidateForSave){
                    const candidateForSaveIndex = state.saveBanners.indexOf(candidateForSave);
                    state.saveBanners.splice(candidateForSaveIndex,1,payload.prevBanner);
                }else{
                    state.saveBanners.push(payload.prevBanner);
                }
                state.selectedBanner = payload.banner;
            }else{
                state.selectedBanner = payload.banner;
            }
        },
        createBanner(state,banner){
            state.bannerList.push(banner);
        },
        editBanner(state,banner){
            const candidate = state.bannerList.find(item => item.id === banner.id);
            if(candidate){
                const candidateIndex = state.bannerList.indexOf(candidate);
                if(candidateIndex >= 0){
                    state.bannerList.splice(candidateIndex,1,banner);
                }
            }
        },
        deleteOneBanner(state,banner){
            const candidate = state.bannerList.find(item => item.id === banner.id);
            if(candidate){
                const candidateIndex = state.bannerList.indexOf(candidate);
                if(candidateIndex >= 0){
                    state.bannerList.splice(candidateIndex,1);
                }
            }
        },
        setError(state,error){
            state.error = error;
        }
    }
}