import $axios from "@/plugins/axios"
const SegmentService = {
  async getSegments(hotel_id){
    try{
      const {data} = await $axios.get(`segments/${hotel_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getSegment(segment_id){
    try{
      const {data} = await $axios.get(`segment/${segment_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async postSegment(segment){
    try{
      const {data} = await $axios.post(`segment`,segment);
      return data;
    }catch (e) {
      return e;
    }
  },
  async putSegment(segment){
    try{
      const {data} = await $axios.put(`segment/${segment.id}`,segment);
      return data;
    }catch (e) {
      return e;
    }
  },
  async deleteSegment(segment_id){
    try{
      const {data} = await $axios.delete(`segment/${segment_id}`);
      return data;
    }catch (e) {
      return e;
    }
  }
};
export default SegmentService;
