
export default {
    namespaced: true,
    state:{
        title_auth:null,
        title_no_auth:null,
        footer_text:null,
        intro_pro:null,
        sliderBranding:null,
        bottomMenu:null
    },
    mutations:{
        setAuthTitle(state,text){
            state.title_auth = text
        },
        setNoAuthTitle(state,text) {
            state.title_no_auth = text;
        },
        setFooterText(state,text){
            state.footer_text = text;
        },
        setIntroPro(state,pro){
          state.intro_pro = pro;
        },
        setSlider(state,slider){
            state.sliderBranding = slider
        },
        setBottomMenu(state,bottomMenu){
            state.bottomMenu = bottomMenu
        }
    },
    getters:{

    },
    actions:{

    }
}