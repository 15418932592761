import productSegmentService from "../services/productSegments.service";

export default {
  namespaced:true,
  state:{
    segmentProductCategories:[],
    segmentProductCategory:null,
    segmentProduct:null,
    error:null
  },
  getters:{

  },
  actions:{
    async getSegmentProductCategories({commit},hotel_id){
      const data = await productSegmentService.getSegmentProductCategories(hotel_id);
      commit('setSegmentProductCategories',data);
    },
    async postSegmentProductCategory({commit},category){
      const data = await productSegmentService.postSegmentProductCategory(category);
      commit('addSegmentProductCategory',data);
    },
    async putSegmentProductCategory({commit},category){
      const data = await productSegmentService.putSegmentProductCategory(category);
      commit('editSegmentProductCategory',data);
    },
    async deleteSegmentProductCategory({commit},category_id){
      const data = await productSegmentService.deleteSegmentProductCategory(category_id);
      commit('deleteSegmentProductCategory',data);
    },
    async postSegmentProduct({commit},product){
      const data = await productSegmentService.postSegmentProduct(product);
      commit('addProduct',data);
    },
    async putSegmentProduct({commit},product){
      const data = await productSegmentService.putSegmentProduct(product);
      commit('editProduct',data);
    },
    async deleteSegmentProduct({commit},product_id){
      const data = await productSegmentService.deleteSegmentProduct(product_id);
      commit('deleteProduct',data);
    }
  },
  mutations:{
    setSegmentProductCategories(state,categories){
      state.segmentProductCategories = categories;
    },
    setSegmentProductCategory(state,category){
      state.segmentProductCategory = category;
    },
    setSegmentProduct(state,product){
      state.segmentProduct = product;
    },
    addSegmentProductCategory(state,category){
      state.segmentProductCategories.push(category);
    },
    editSegmentProductCategory(state,category){
      const candidate = state.segmentProductCategories.find(x => x.id === category.id);
      if(candidate){
        const candidateIndex = state.segmentProductCategories.indexOf(candidate);
        if(candidateIndex>=0){
          state.segmentProductCategories.splice(candidateIndex,1,category);
        }
      }
    },
    deleteSegmentProductCategory(state,category){
      const candidate = state.segmentProductCategories.find(x => x.id === category.id);
      if(candidate){
        const candidateIndex = state.segmentProductCategories.indexOf(candidate);
        if(candidateIndex>=0){
          state.segmentProductCategories.splice(candidateIndex,1);
        }
      }
    },
    addProduct(state,product){
      console.log(product);
      const candidateCategory = state.segmentProductCategories.find(x => x.id === product.segment_product_category_id);
      if(candidateCategory){
        const candidateCategoryIndex = state.segmentProductCategories.indexOf(candidateCategory);
        if(candidateCategoryIndex>=0){
          state.segmentProductCategories[candidateCategoryIndex].products.push(product);
        }
      }
    },
    editProduct(state,product){
      const candidateCategory = state.segmentProductCategories.find(x => x.id === product.segment_product_category_id);
      if(candidateCategory){
        const candidateCategoryIndex = state.segmentProductCategories.indexOf(candidateCategory);
        if(candidateCategoryIndex>=0){
          const candidateProduct = state.segmentProductCategories[candidateCategoryIndex].products.find(x => x.id === product.id);
          if(candidateProduct){
            const candidateProductIndex = state.segmentProductCategories[candidateCategoryIndex].products.indexOf(candidateProduct);
            if(candidateProductIndex>=0){
              state.segmentProductCategories[candidateCategoryIndex].products.splice(candidateProductIndex,1,product);
            }
          }
        }
      }
    },
    deleteProduct(state,product){
      const candidateCategory = state.segmentProductCategories.find(x => x.id === product.segment_product_category_id);
      if(candidateCategory){
        const candidateCategoryIndex = state.segmentProductCategories.indexOf(candidateCategory);
        if(candidateCategoryIndex>=0){
          const candidateProduct = state.segmentProductCategories[candidateCategoryIndex].products.find(x => x.id === product.id);
          if(candidateProduct){
            const candidateProductIndex = state.segmentProductCategories[candidateCategoryIndex].products.indexOf(candidateProduct);
            if(candidateProductIndex>=0){
              state.segmentProductCategories[candidateCategoryIndex].products.splice(candidateProductIndex,1);
            }
          }
        }
      }
    },
    setError(state,error){
      state.error = error;
    }
  }
}
