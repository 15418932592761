<template>
    <v-app class="app app_admin">
        <div class="page" v-if="$store.state.profile.id&&!isMobile">
            <Sidebar @hide="hide"/>
          <div class="main" :style="`padding:${$route.name === 'Constructor'?'40px 0 20px':'40px 30px 20px'};margin-left:${left};width:${width};`" v-if="$store.state.profile.name">
            <div class="header" style="padding-right: 30px;">
              <span style="margin-right: 15px;font-weight: bold;" id="userName">{{$store.state.profile.name}}</span>
              <v-menu
                  bottom
                  offset-y
                  open-on-hover
                  close-on-content-click
                  close-delay="2"
                  transition="slide-y-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar class="avatar">
                    <img
                            style="height: 100%;width: 100%;"
                        :src="$store.state.profile.avatar ? `${VUE_APP_STORAGE_URL+$store.state.profile.avatar}` : require('@/assets/icons/user_placeholder.svg')"
                        alt="John"
                        v-bind="attrs"
                        v-on="on"
                    >
                  </v-avatar>
                </template>
                <v-list >
                  <v-list-item class="menu__item">
                    <v-list-item-title @click="createFacility" >Create Facility</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="menu__item">
                    <v-list-item-title @click="listFacility">My Facilities</v-list-item-title>
                  </v-list-item>
                    <v-list-item class="menu__item">
                        <v-list-item-title @click="goToProfile">My Profile</v-list-item-title>
                    </v-list-item>
                  <v-list-item class="menu__item">
                      <v-list-item-title @click="logout"><v-icon>mdi-exit-to-app</v-icon> Sign Out</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <slot />
            <div style="margin-bottom:50px;"></div>
            <div v-if="!$route.path.includes('constructor')" class="footer" style="height:70px;position: absolute;bottom: 0;width:calc(100% - 250px);left:250px;">
              <ul>
                <li style="cursor: default;">Tourist © 2022</li>
                <li @click="routeTo('Legal')">Privacy & Legal</li>
                <li @click="routeTo('Contact')">Contact</li>
              </ul>
            </div>
          </div>
        </div>
      <div class="page" v-if="errorText">{{errorText}}</div>
      <div class="page" v-if="isMobile" style="height:100%;width:100%;display: flex;flex-direction: column;">
          <MobileHeader/>
          <MobileMain style="flex:1 1 auto;padding:15px;">
            <slot/>
          </MobileMain>
          <MobileMenu style="width:100%;position: fixed;bottom: 0;background: #fff;z-index:9;"/>
      </div>
      <div v-if="!$store.state.profile.id&&!loading" style="display: flex;width:100vw;height:100vh;flex-direction:column;align-items: center;justify-content: center;">
        <div style="margin-bottom: 15px;">Your account has been blocked permanently.</div>
        <v-btn outlined small color="primary" @click="logout">Logout</v-btn>
      </div>
    </v-app>
</template>

<script>
import Sidebar from '@/components/Sidebar';
import Header from '@/components/Sidebar';
import store from "@/store";
import AgencyService from "../services/agency.service";
import MobileMain from "../components/Mobile/MobileMain";
import MobileMenu from "../components/Mobile/MobileMenu";
import MobileHeader from "../components/Mobile/MobileHeader";

export default {
    components: {MobileHeader, MobileMenu, MobileMain, Sidebar },
  name:"DefaultLayout",
    data: () => ({
      loading:true,
      isMobile:false,
      errorText:"",
        hideMenu:false,
      menuItems:[],
      helpItems:[
        {
          id:0,
          question:"How to order stickers or cards for rooms and reception?",
          answer:`Send us an <a href="mailto:guest@tourist.com">email</a> and tell us your wishes.`
        },
        {
          id:1,
          question:"Do you have a designer who will prepare the layout of stickers and cards?",
          answer:`Yes, we provide such services, please contact us. contact us by <a href="mailto:guest@tourist.com">email</a>`
        },
        {
          id:2,
          question:"Can you help me customize the appearance of the app?",
          answer:`Yes, send an <a href="mailto:guest@tourist.com">email</a> request`
        },
        {
          id:3,
          question:"Do you provide advice on how best to make an app for a hotel, camping, etc.?",
          answer:`Yes,  <a href="mailto:guest@tourist.com">email</a> us your request and we will help`
        },
      ],
      logoSettings:{
        position:"top",
        link:"https://guest.mobi",
        image:"https://guest.mobi/logo.svg",
        mini:"https://guest.mobi/logo_mini.svg"
      },
      tariffSettings:{
        default:true,
        default_text:`<p>You cannot change the rate here by yourself, please contact us</p>`
      },
      authorisation:{
        background:"https://guest.mobi/background_partner.jpg",
        logo:"https://guest.mobi/logo.svg",
        register:"<p>Thank you for your registration in the web application builder for hotels, apartments, etc.</p><p>To confirm your email address, please follow the link: $link</p><p>Best regards, Tourist Team!</p>",
        recover:"<p>Hello. To recover your password, follow the link: $link</p>"
      },
      contact:`
                <h1>Contact</h1>
                <h4 style="font-weight: normal;">If you have any questions or suggestions. then please write to us at <a href="mailto:guest@tourist.com">guest@tourist.com</a></h4>`,
      legal:`
      <h1>Privacy & Legal</h1>
      <h4 style="font-weight: normal;">We will add information to this page soon.</h4>`
    }),
  async created() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if(!this.$store.state.profile.id){

          try{
            const user = await store.dispatch('getProfile');
            if(user.status === 422){
              this.errorText = "Your account has been blocked permanently";
            }
          }catch (e){
            console.log(e);
          }
    }
    if(this.$store.state.profile.is_agency){
      this.$store.state.settings = await AgencyService.getAgencySettings(this.$store.state.profile.id);
      if(Array.isArray(this.$store.state.settings)){
        const payload = {
          user_id:this.$store.state.profile.id,
          contact:this.contact,
          legal:this.legal,
          menu:this.menuItems,
          help:this.helpItems,
          domain:"",
          logo:this.logoSettings,
          authorisation:this.authorisation,
          tariff:this.tariffSettings
        }
        this.$store.state.settings = await AgencyService.postAgencySettings(payload);
      }
    }
    if(this.$store.state.profile.agency_id){
      this.$store.state.settings = await AgencyService.getAgencySettings(this.$store.state.profile.agency_id);
      if(Array.isArray(this.$store.state.settings)){
        const payload = {
          user_id:this.$store.state.profile.agency_id,
          contact:this.contact,
          legal:this.legal,
          menu:this.menuItems,
          help:this.helpItems,
          logo:this.logoSettings,
          domain:"",
          authorisation:this.authorisation,
          tariff:this.tariffSettings
        }
        this.$store.state.settings = await AgencyService.postAgencySettings(payload);
      }
    }
    this.loading = false;
  },
  computed:{
      VUE_APP_STORAGE_URL(){
        return process.env.VUE_APP_STORAGE_URL;
      },

    width(){
      if(this.hideMenu){
        return 'calc(100% - 105px);'
      }else{
        return 'calc(100% - 250px)';
      }
    },
    left(){
      if(this.hideMenu){
        return '105px'
      }else{
        return '250px'
      }
    },
  },

  methods:{
    extractHostname(url) {
      let hostname;
      if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
      } else {
        hostname = url.split('/')[0];
      }
      hostname = hostname.split(':')[0];
      hostname = hostname.split('?')[0];

      return hostname;
    },
      async goToProfile(){
          await this.$router.push('/settings');
      },
    hide(hide){
      this.hideMenu = hide;
    },
      async routeTo(page){
      await this.$router.push({name:page});
    },
      async logout() {
      this.$store.dispatch('logout');
      await this.$router.push('/')
    },
      async createFacility(){
      await this.$router.push({
        name: "ObjectsCreate",
        params: { lang: this.$route.params.lang},
      });
    },
      async listFacility(){
      await this.$router.push({
        name: "objects",
        params: { lang: this.$route.params.lang},
      });
    }
  }
};
</script>
<style lang="scss">
.app_admin {
    @import '@/scss/style';
}
.footer{
  display: flex;
  justify-content: center;
  align-items: center;
  ul{
    display: flex;
    width:100%;
    justify-content: center;
    align-items: center;
    list-style: none;
    li{
      color:#5c5d61;
      font-size:14px;
      font-weight: 500;
      cursor: pointer;
      padding:0 20px;
    }
  }
}
.header{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .help{
    cursor: pointer;
    &:hover{
      color:#000;
    }

  }
  .avatar{
    height: 60px;
    width:60px;
    border:1px solid #eee;
    &:hover{
      border:1px solid #000;
    }
  }
}
.menu__item{
  cursor: pointer;
  .v-list-item__title{
    color:#7f8385 !important;

  }
  &:hover{
    .v-list-item__title{
      color:#000 !important;
    }
  }
}
</style>
