import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import en from 'vuetify/es5/locale/en'
import ru from 'vuetify/es5/locale/ru';
import hr from 'vuetify/es5/locale/hr'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { ru, en, hr },
        current: localStorage.getItem('current_locale') || 'en'
    },
    theme: {
        themes: {
          light: {
            primary: '#007db5',
            accent: '#007db5',
            greylight: '#fafafa',
            error: '#ff5416',
            success: '#3fa535'
          },
        },
    },
});
