import $axios from "./plugins/axios";
import App from "./App.vue";
import DefaultLayout from "./layouts/DefaultLayout.vue";
import EmptyLayout from "./layouts/EmptyLayout.vue";
import LandingLayout from "./layouts/LandingLayout.vue";
import DashboardLayout from "./layouts/DashboardLayout.vue";
import Vue from "vue";
import VueProgressBar from "vue-progressbar";
import externalLink from "./plugins/external-link";
import i18n from "./plugins/i18n";
import moment from "./plugins/moment";
import router from "./router";
import store from "./store";
import vMask from "./plugins/v-mask";
import vuetify from "./plugins/vuetify";
import VueScrollTo from "vue-scrollto";
import VueOffline from "vue-offline";
import * as VueGoogleMaps from "vue2-google-maps";
import VueQRCodeComponent from "vue-qrcode-component";
import VCurrencyField from "v-currency-field";
import Editor from "@tinymce/tinymce-vue";

import VuePapaParse from "vue-papa-parse";
Vue.use(VuePapaParse);
import VueNumericInput from "vue-numeric-input";
import { StripePlugin } from "@vue-stripe/vue-stripe";

import { VTextField } from "vuetify/lib";

Vue.component("Editor", Editor);
Vue.component("v-text-field", VTextField);
Vue.use(VCurrencyField, {
  locale: "en-En",
  decimalLength: 0,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
});
Vue.component("qr-code", VueQRCodeComponent);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB1InM-prsgpnZSF43dKG6nWgqmOoYk4_c', //test
    // key: "AIzaSyCdUANK2bUuEAMYLWQjJeROckCuJYyI6u0", //original
    libraries: "places",
    v: "3.48.10",
  },

  autobindAllEvents: false,
  installComponents: true,
});

Vue.use(VueOffline);
Vue.use(VueNumericInput);
Vue.component("empty-layout", EmptyLayout);
Vue.component("default-layout", DefaultLayout);
Vue.component("landing-layout", LandingLayout);
Vue.component("dashboard-layout", DashboardLayout);

Vue.use(VueScrollTo);

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});
const optionsProgress = {
  color: "#007db5",
  failedColor: "#874b4b",
  thickness: "2px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

Vue.use(VueProgressBar, optionsProgress);

const optionsStripe = {
  pk: "pk_test_51KHTjUF36FbeQVf0ddUXNACQCnNDtVjT4hWhAd6sCMS4vu8XJE872IueTh5GOnz02RrQT303FqWhjL5ySa30yBrK00iSicJMXa",
};
Vue.use(StripePlugin, optionsStripe);

Vue.config.productionTip = true;

new Vue({
  router,
  store,
  $axios,
  vuetify,
  externalLink,
  vMask,
  moment,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
