
export default {
    namespaced:true,
    state:{
        reviews:null
    },
    getters:{

    },
    mutations:{
        setReviews(state,reviews){
            state.reviews = reviews;
        }
    },
    actions:{

    }
}