import BottomBannerService from "@/services/bottomBanner.service";

export default {
    namespaced:true,
    state:{
        bottomBannersList:[],
        bottomBanner:null,
        error:null,
        preview:false
    },
    getters:{

    },
    actions:{
        async getBottomBanners({commit},hotel_id){
            try{
                const data = await BottomBannerService.getBottomBanners(hotel_id);
                commit('setBottomBanners',data);
            }catch (e){
                commit('setError',e);
            }
        },
        async getBottomBanner({commit},banner_id){
            try{
                const data = await BottomBannerService.getBottomBanner(banner_id);
                commit('setBottomBanner',data);
            }catch (e){
                commit('setError',e);
            }
        },
        async postBottomBanner({commit},banner){
            try{
                const data = await BottomBannerService.postBottomBanner(banner);
                commit('addBottomBanner',data);
            }catch (e){
                commit('setError',e);
            }
        },
        async putBottomBanner({commit},banner){
            try{
                const data = await BottomBannerService.putBottomBanner(banner);
                commit('editBottomBanner',data);
            }catch (e){
                commit('setError',e);
            }
        },
        async deleteBottomBanner({commit},banner_id){
            try{
                const data = await BottomBannerService.deleteBottomBanner(banner_id);
                commit('deleteBottomBanner',data);
            }catch (e){
                commit('setError',e);
            }
        }
    },
    mutations:{
        setPreview(state,preview){
          state.preview = preview;
        },
        setError(state,error){
          state.error = error;
        },
        setBottomBanners(state,list){
            state.bottomBannersList = list
        },
        setBottomBanner(state,banner){
            state.bottomBanner = banner;
        },
        addBottomBanner(state,banner){
            state.bottomBannersList.push(banner);
        },
        editBottomBanner(state,banner){
            const candidate = state.bottomBannersList.find(item => item.id === banner.id);
            if(candidate){
                const candidateIndex = state.bottomBannersList.indexOf(candidate);
                if(candidateIndex>=0){
                    state.bottomBannersList.splice(candidateIndex,1,banner);
                }
            }
        },
        deleteBottomBanner(state,banner){
            const candidate = state.bottomBannersList.find(item => item.id === banner.id);
            if(candidate){
                const candidateIndex = state.bottomBannersList.indexOf(candidate);
                if(candidateIndex>=0){
                    state.bottomBannersList.splice(candidateIndex,1);
                }
            }
        },
    }
}