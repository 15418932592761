<template>
    <v-app class="app">
        <slot/>
    </v-app>
</template>

<script>
export default {
};
</script>

<style lang="scss">
// .app_admin {
//     @import '@/scss/style';
// }
</style>