import ApiService from "@/services/api.services.js"

export default {
    namespaced: true,
    state: {
      selectedPage:"main",
        previewBg:false,
        previewLangBg:false,
      loaderState:{
        icon:process.env.VUE_APP_LINK+"/loaders/loader11.svg",
        icon_color:"#ccc",
        background:"#fff",
        size:50,
        text:"<p style='text-align: center;'>Please wait a bit</p>",
        hotel_id:null
    },
    show:false
    },
    mutations: {
        setLoader(state,loader){
            if(loader.length){
                state.loaderState = loader[0];
            }
        },
        setSelectedPage(state,page){
            state.selectedPage = page;
        }
    },
    getters:{
        
    },
    actions: {
        async getLoader({commit},hotel_id){
            try{
                const {data} = await ApiService.get(`/loader/${hotel_id}`);
                commit('setLoader',data);
            }catch(e){
                return e
            }
        },
        async toServerLoader({commit},payload){
            try{
                if(payload.id){
                    const {data} = await ApiService.put(`/loader/${payload.id}`,payload);
                    commit('setLoader',data);
                }else{
                    const {data} = await ApiService.post(`/loader`,payload);
                    commit('setLoader',data);
                }
            }catch(e){
                return e
            }
        },
        
    },
}