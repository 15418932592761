export default {
    namespaced:true,
    state:{
        selectedMenuItem:null,
        list:[]
    },
    mutations:{
        setMenuList(state,list){
          state.list = list;
        },
        setSelectedMenuItem(state,menu){
          state.selectedMenuItem = menu;
        },
    },
    getters:{
        getActiveList(state){
            return state.list.filter(x => x.active);
        }
    },
    actions:{

    }
}