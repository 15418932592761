import $axios from "@/plugins/axios";

const PasscodeService = {
  async getPasscodeByHotel(hotel_id){
    try{
      const {data} = await $axios.get(`passcodes/${hotel_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getPasscodeById(passcode_id){
    try{
      const {data} = await $axios.get(`passcode/${passcode_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async putPasscode(passcode){
    try{
      const {data} = await $axios.put(`passcode/${passcode.id}`,passcode);
      return data;
    }catch (e) {
      return e;
    }
  },
  async postPasscode(passcode){
    try{
      const {data} = await $axios.post(`passcode`,passcode);
      return data;
    }catch (e) {
      return e;
    }
  },
  async deletePasscode(passcode_id){
    try{
      const {data} = await $axios.get(`passcode/${passcode_id}`);
      return data;
    }catch (e) {
      return e;
    }
  }


};
export default PasscodeService;
