import Vue from 'vue'
import axios from 'axios'
import store from '@/store'

const $axios = axios.create({
    baseURL: process.env.VUE_APP_API_URL, 
    headers: { 
      'Content-Type': 'application/json',
      // 'Accept': 'application/json',
      // "Access-Control-Allow-Origin": "*",
      'Accept-Language': 'en',
    },
});

if (store.state.token) {
    $axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`;
}

Vue.prototype.$axios = $axios;

export default $axios;