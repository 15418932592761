import QuestsService from "../services/quests.services";

export default {
    namespaced: true,
    state: {
        myQuestCategories:[],
        mySelectedQuestCategory:null,
        mySelectedQuest:null,
        mySelectedGroup:null,
        questCategories:[],
        selectedCategory:null,
        selectedQuest:null,
        selectedGroup:null,
        questsList:{
            groups:[],
            quests:[]
        },
        quest:null,
        clientQuestList:[],
        hotelQuestList:{
            groups:[],
            quests:[]
        },
        finishPage:null,
      clientsBalances:[]
    },
    mutations: {
        setClientsBalances(state,data){
          state.clientsBalances = data;
        },
        setFinishPage(state,data){
          state.finishPage = data;
        },
        editQuestCategory(state,category){
          const candidate = state.questCategories.find(item => item.id === category.id);
          if(candidate){
              const candidateIndex = state.questCategories.indexOf(candidate);
              if(candidateIndex>=0){
                  state.questCategories.splice(candidateIndex,1,category);
              }
          }
        },
        createQuestCategory(state,category){
          state.questCategories.push(category);
        },
        setQuestCategories(state,list){
          state.questCategories = list;
        },
        setSelectedCategory(state,category){
          state.selectedCategory = category;
        },
        setHotelQuestList(state,list){
            state.hotelQuestList = list;
        },
        setClientQuestList(state,list){
          state.clientQuestList = list;
        },
        setQuestsList(state,list){
            state.questsList = list;
        },
        addSingleQuest(state,quest) {
            if(quest.quest_group_id){
                const candidate = state.selectedCategory.quest_groups.find(group => group.id === quest.quest_group_id);
                if(candidate){
                    const candidateIndex = state.selectedCategory.quest_groups.indexOf(candidate);
                    state.selectedCategory.quest_groups[candidateIndex].quests.push(quest);
                }
            }else{
                if(state.selectedCategory.quests){
                    state.selectedCategory.quests.push(quest);
                }
            }
        },
        addGroupQuest(state,group) {
            if(state.selectedCategory.quest_groups){
                state.selectedCategory.quest_groups.push(group);
            }
        },
        deleteQuestGroup(state,id){
            const candidate = state.selectedCategory.quest_groups.find(group => group.id === id);
            if(candidate){
                const candidateIndex = state.selectedCategory.quest_groups.indexOf(candidate);
                state.selectedCategory.quest_groups.splice(candidateIndex,1);
            }
        },
        editQuestGroup(state, group) {
            const candidate = state.selectedCategory.quest_groups.find(item => item.id === group.id);
            if(candidate){
                const candidateIndex = state.selectedCategory.quest_groups.indexOf(candidate);
                state.selectedCategory.quest_groups.splice(candidateIndex,1,group);
            }
        },
        deleteQuestSingle(state, quest){
            if(quest.quest_group_id){
                const candidateGroup = state.selectedCategory.quest_groups.find(group => group.id === quest.quest_group_id);
                if(candidateGroup){
                    const candidateIndex = state.selectedCategory.quest_groups.indexOf(candidateGroup);
                    const candidateQuest = state.selectedCategory.quest_groups[candidateIndex].quests.find(item => item.id === quest.id);
                    if(candidateQuest){
                        const candidateQuestIndex = state.selectedCategory.quest_groups[candidateIndex].quests.indexOf(candidateQuest);
                        state.selectedCategory.quest_groups[candidateIndex].quests.splice(candidateQuestIndex,1);
                    }
                }
            }else{
                const candidate = state.selectedCategory.quests.find(item => item.id === quest.id);
                if(candidate){
                    const candidateIndex = state.selectedCategory.quests.indexOf(candidate);
                    state.selectedCategory.quests.splice(candidateIndex,1);
                }
            }
        },
        editQuestSingle(state, quest){
            if(quest.quest_group_id){
                const candidateGroup = state.selectedCategory.quest_groups.find(group => group.id === quest.quest_group_id);
                if(candidateGroup){
                    const candidateIndex = state.selectedCategory.quest_groups.indexOf(candidateGroup);
                    const candidateQuest = state.selectedCategory.quest_groups[candidateIndex].quests.find(item => item.id === quest.id);
                    if(candidateQuest){
                        const candidateQuestIndex = state.selectedCategory.quest_groups[candidateIndex].quests.indexOf(candidateQuest);
                        state.selectedCategory.quest_groups[candidateIndex].quests.splice(candidateQuestIndex,1,quest);
                    }
                }
            }else{
                const candidate = state.selectedCategory.quests.find(item => item.id === quest.id);
                if(candidate){
                    const candidateIndex = state.selectedCategory.quests.indexOf(candidate);
                    state.selectedCategory.quests.splice(candidateIndex,1,quest);
                }
            }
        },
        addQuestGroupToUser(state,group){
            const candidate = state.selectedCategory.quest_groups.find(item => item.id === group.id);
            if(candidate){
                const candidateIndex = state.selectedCategory.quest_groups.indexOf(candidate);
                state.selectedCategory.quest_groups.splice(candidateIndex,1);
            }
        },
        addQuestToUser(state,quest){
            if(quest.quest_group_id){
                const candidateGroup = state.selectedCategory.quest_groups.find(x => x.id === quest.quest_group_id);
                if(candidateGroup){
                    const candidateGroupIndex = state.selectedCategory.quest_groups.indexOf(candidateGroup);
                    const candidateQuest = state.selectedCategory.quest_groups[candidateGroupIndex].quests.find(x => x.id === quest.id);
                    if(candidateQuest){
                        const candidateQuestIndex = state.selectedCategory.quest_groups[candidateGroupIndex].quests.indexOf(candidateQuest);
                        state.selectedCategory.quest_groups[candidateGroupIndex].quests.splice(candidateQuestIndex,1);
                    }
                }
            }else{
                const candidate = state.selectedCategory.quests.find(x => x.id === quest.id);
                if(candidate){
                    const candidateIndex = state.selectedCategory.quests.indexOf(candidate);
                    state.selectedCategory.quests.splice(candidateIndex,1);
                }
            }
        },
        deleteQuestCategory(state,category){
            const candidate = state.questCategories.find(item => item.id === category.id);
            if(candidate){
                const candidateIndex = state.questCategories.indexOf(candidate);
                if(candidateIndex>=0){
                    state.questCategories.splice(candidateIndex,1);
                }
            }
        },
        setMyQuestCategories(state,list){
            state.myQuestCategories = list;
        },
        setMySelectedQuestCategory(state,category){
            state.mySelectedQuestCategory = category;
        },
        setQuestStarted(state,quest){
            if(quest.quest_group_id){
                let candidateGroup;
                let candidateCategoryIndex;
                let candidateGroupIndex;
                for(let i = 0;i<state.myQuestCategories.length;i++){
                    candidateGroup = state.myQuestCategories[i].quest_groups.find(x => x.id === quest.quest_group_id);
                    if(candidateGroup){
                        candidateGroupIndex = state.myQuestCategories[i].quest_groups.indexOf(candidateGroup);
                        candidateCategoryIndex = i;
                        break;
                    }
                }
                if(candidateGroup){
                    const candidateQuest = state.myQuestCategories[candidateCategoryIndex].quest_groups[candidateGroupIndex].quests.find(x => x.id === quest.id);
                    if(candidateQuest){
                        const candidateQuestIndex = state.myQuestCategories[candidateCategoryIndex].quest_groups[candidateGroupIndex].quests.indexOf(candidateQuest);
                        state.myQuestCategories[candidateCategoryIndex].quest_groups[candidateGroupIndex].quests.splice(candidateQuestIndex,1,quest);
                    }
                }
            }else{
                const candidateCategory = state.myQuestCategories.find(x => x.id === quest.quest_category_id);
                if(candidateCategory){
                    const candidateCategoryIndex = state.myQuestCategories.indexOf(candidateCategory);
                    const candidateQuest = state.myQuestCategories[candidateCategoryIndex].quests.find(x => x.id === quest.id);
                    if(candidateQuest){
                        const candidateQuestIndex = state.myQuestCategories[candidateCategoryIndex].quests.indexOf(candidateQuest);
                        state.myQuestCategories[candidateCategoryIndex].quests.splice(candidateQuestIndex,1,quest);
                    }
                }
            }
        }
    },
    getters:{

    },
    actions: {
        async startQuest({commit},payload){
            try{
                const data = await QuestsService.startMyQuest(payload);
                commit('setQuestStarted',data);
            }catch(e){
                return e;
            }
        },
        async getFinishPage({commit},hotel_id){
          try{
              const data = await QuestsService.getFinishPage(hotel_id);
              commit('setFinishPage',data);
          }  catch(e){
              return e;
          }
        },
        async editFinishPage({commit},payload){
            try{
                const data = await QuestsService.editFinishPage(payload);
                commit('setFinishPage',data);
            }catch (e) {
                return e;
            }
        },
        async getQuestCategories({commit},hotel_id){
            try{
                const data = await QuestsService.getQuestCategories(hotel_id);
                commit('setQuestCategories',data);
            }catch (e){
                return e;
            }
        },
        async getQuestCategoriesHotel({commit},payload){
            try{
                const data = await QuestsService.getQuestCategoriesHotel(payload);
                commit('setQuestCategories',data);
            }catch (e){
                return e;
            }
        },
        async getMyQuestCategories({commit},payload){
            try{
                const data = await QuestsService.getMyQuestCategories(payload);
                commit('setMyQuestCategories',data);
            }catch(e){
                return e;
            }
        },
        async getHotelQuestList({commit},payload){
            try{
                const data = await QuestsService.getHotelQuestList(payload);
                commit('setHotelQuestList',data);
            }catch (e){
                return e;
            }
        },
        async getClientQuestList({commit},payload){
          try{
              const data = await QuestsService.getClientQuestsList(payload);
              commit('setClientQuestList',data);
          }  catch (e) {
              return e;
          }
        },
        async getQuestsList({commit},hotel_id){
            try{
                const data = await QuestsService.getQuestsList(hotel_id);
                commit('setQuestsList',data);
            }catch (e) {
                return e;
            }

        },
        async createSingleQuestFunc({commit},payload){
          try{
            const data = await QuestsService.addSingleQuest(payload);
            commit('addSingleQuest',data);
          }  catch (e) {
              return e;
          }
        },
        async createQuestGroup({commit},payload){
            try {
                const data = await QuestsService.addGroupQuest(payload);
                commit('addGroupQuest',data);
            }catch (e) {
                return e;
            }
        },
        async deleteQuestGroup({commit},id){
            try{
                await QuestsService.deleteQuestGroup(id);
                commit('deleteQuestGroup',id);
            }catch (e){
                return e;
            }
        },
        async editQuestGroup({commit},payload){
            try{
                const data = await QuestsService.editQuestGroup(payload);
                commit('editQuestGroup',data);
            }catch(e){
                return e;
            }
        },
        async deleteQuestSingle({commit},id){
            try {
                const data = await QuestsService.deleteQuestSingle(id);
                commit('deleteQuestSingle',data);
            }catch (e) {
                return e;
            }
        },
        async editQuestSingle({commit},payload){
            try{
                const data = await QuestsService.editQuestSingle(payload);
                commit('editQuestSingle',data);
            }catch (e) {
                return e;
            }
        },
        async addQuestGroupToUser({commit},payload){
            try{
                const data = await QuestsService.addQuestGroupToUser(payload);
                commit('addQuestGroupToUser',data);
            }catch(e){
                return e;
            }
        },
        async addQuestToUser({commit},payload){
            try{
                const data = await QuestsService.addQuestToUser(payload);
                commit('addQuestToUser',data);
            }catch(e){
                return e;
            }
        },
        async deleteQuestCategory({commit},payload){
            try{
                const data = await QuestsService.deleteQuestCategory(payload);
                commit('deleteQuestCategory',data);
            }catch(e){
                return e;
            }
        },
        async createQuestCategory({commit},payload){
            try{
                const data = await QuestsService.createQuestCategory(payload);
                commit('createQuestCategory',data);
            }catch(e){
                return e;
            }
        },
        async editQuestCategory({commit},payload){
            try{
                const data = await QuestsService.editQuestCategory(payload);
                commit('editQuestCategory',data);
            }catch(e){
                return e;
            }
        },
      async getClientsBalances({commit},hotel_id){
          try{
            const data = await QuestsService.getClientsBalance(hotel_id);
            commit('setClientsBalances',data)
          }catch (e) {
            return e;
          }
      }
    },
}
