import $axios from "@/plugins/axios";

const IntroServices = {
    async getIntroBanners(hotel_id){
        try{
            const {data} = await $axios.get(`intro-banners/${hotel_id}`);
            return data;
        }catch(e){
            return e;
        }
    },
    async getIntroBanner(banner_id){
        try{
            const {data} = await $axios.get(`intro-banner/${banner_id}`);
            return data;
        }catch (e){
            return e;
        }
    },
    async postIntroBanner(banner){
        try{
            const {data} = await $axios.post(`intro-banner`,banner);
            return data;
        }catch(e){
            return e;
        }
    },
    async putIntroBanner(banner){
        try{
            const {data} = await $axios.put(`intro-banner/${banner.id}`,banner);
            return data;
        }catch(e){
            return e;
        }
    },
    async deleteIntroBanner(banner_id){
        try{
            const {data} = await $axios.delete(`intro-banner/${banner_id}`);
            return data;
        }catch(e){
            return e;
        }
    },
    async postIntroBannerStatistic(payload){
        try{
            const {data} = await $axios.post(`add-intro-banner-statistic`,payload);
            return data;
        }catch(e){
            return e;
        }
    },
    async getIntroBannerStatistic(payload){
        try{
            const {data} = await $axios.get(`into-banner-statistic/${payload.hotel_id}?from=${payload.date_start}&to=${payload.date_end}`)
            return data;
        }catch(e){
            return e;
        }
    },
    async deleteIntroBannerStatistic(hotel_id){
        try{
            const {data} = await $axios.delete(`into-banner-statistic/${hotel_id}`);
            return data;
        }catch(e){
            return e;
        }
    },
    async exportIntroBannerStatistic(hotel_id){
        try{
            const {data} = await $axios.get(`export-intro-banner-statistic/${hotel_id}`);
            return data;
        }catch(e){
            return e;
        }
    }
}
export default IntroServices;