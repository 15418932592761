import ApiService from "@/services/api.services.js"

export default {
    namespaced: true,
    state: {
        rooms: [],
        roomCategories:[],
        selectedContent:null
    },
    mutations: {
        setCategories(state,categories){
            state.roomCategories = categories;
        },
        newRoomCategory(state,category){
            state.roomCategories.push(category);
        },
        addNewRoom(state,room){
            const candidate = state.roomCategories.find(x => x.id === room.room_category_id);
            if(candidate){
                const categoryIndex = state.roomCategories.indexOf(candidate);
                state.roomCategories[categoryIndex].rooms.push(room);
            }
        },
        addNewRoomsArr(state,rooms){
            const candidate = state.roomCategories.find(x => x.id === rooms[0].room_category_id);
            if(candidate){
                const categoryIndex = state.roomCategories.indexOf(candidate);
                state.roomCategories[categoryIndex].rooms = [...state.roomCategories[categoryIndex].rooms,...rooms]
            }
        },
        deleteCategory(state,category){
            const candidate = state.roomCategories.find(x => x.id === category.id);
            if(candidate){
                const categoryIndex = state.roomCategories.indexOf(candidate);
                state.roomCategories.splice(categoryIndex,1);
            }
        },
        setChangesCategory(state,category){
            const candidate = state.roomCategories.find(x => x.id === category.id);
            if(candidate){
                const categoryIndex = state.roomCategories.indexOf(candidate);
                state.roomCategories[categoryIndex].name = category.name;
            }
        },
        deleteRoomConfirm(state,room){
            const categoryCandidate = state.roomCategories.find(x => x.id === room.room_category_id);
            if(categoryCandidate){
                const candidate = categoryCandidate.rooms.find(x => x.id === room.id);
                if(candidate){
                    const categoryIndex = state.roomCategories.indexOf(categoryCandidate);
                    const roomIndex = state.roomCategories[categoryIndex].rooms.indexOf(candidate);
                    state.roomCategories[categoryIndex].rooms.splice(roomIndex,1);
                }
            }
        },
        changeRoomUpdate(state,room){
            console.log(room);
            const categoryCandidate = state.roomCategories.find(x => x.id === room.room_category_id);
            console.log(categoryCandidate);
            if(categoryCandidate){
                const categoryIndex = state.roomCategories.indexOf(categoryCandidate);
                const candidate = state.roomCategories[categoryIndex].rooms.find(x => x.id === room.id);
                if(candidate){
                    const roomIndex = state.roomCategories[categoryIndex].rooms.indexOf(candidate);
                    state.roomCategories[categoryIndex].rooms.splice(roomIndex,1,room);
                }else{
                    state.roomCategories[categoryIndex].rooms.push(room);
                }
            }
        }
    },
    getters:{
        
    },
    actions: {
        async getRoomCategories({commit},hotel_id){
            try{
                const {data} = await ApiService.get(`/room-categories?hotel_id=${hotel_id}`)
                commit('setCategories',data);
            }catch(e){
                return e;
            }
        },
        async createdNewRoomCategory({commit},payload){
            try{
                const {data} = await ApiService.post(`room-category`,payload);
                commit('newRoomCategory',data);
            }catch(e){
                return e;
            }
        },
        async createNewRoom({commit},payload){
            try{
                const {data} = await ApiService.post('hotel-room',payload);
                if(Array.isArray(data)){
                    commit('addNewRoomsArr',data);
                }else{
                    commit('addNewRoom',data);
                
                }
            }catch(e){
                return e;
            }
        },
        async changeRoomCategory({commit},payload){
            try{
                const {data} = await ApiService.put('room-category/'+payload.id,payload);
                commit('setChangesCategory',data);
                
            }catch(e){
                return e;
            }
        },
        async deleteRoomCategory({commit},payload){
            try{
                const {data} = await ApiService.delete('room-category/'+payload);
                commit('deleteCategory',data);
            }catch(e){
                return e;
            }
        },
        async changeRoom({commit},payload){
            try{
                const {data} = await ApiService.put('hotel-room/'+payload.id,payload);
                if(payload.old_category){
                    payload.room_category_id = payload.old_category;
                    commit('deleteRoomConfirm',payload);
                }
                commit('changeRoomUpdate',data);
            }catch(e){
                return e;
            }
        },
        async deleteRoom({commit},payload){
            try{
                const {data} = await ApiService.delete('hotel-room/'+payload);
                commit('deleteRoomConfirm',data);
            }catch(e){
                return e;
            }
        }
    },
}