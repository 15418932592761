import $axios from "@/plugins/axios"

const productSegmentService = {
  async getSegmentProductCategories(hotel_id){
    try{
      const { data } = await $axios.get(`segment-product-categories/${hotel_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getSegmentProductCategory(category_id){
    try{
      const {data} = await $axios.get(`segment-product-category/${category_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async postSegmentProductCategory(category){
    try{
      const {data} = await $axios.post(`segment-product-category`,category);
      return data;
    }catch (e) {
      return e;
    }
  },
  async putSegmentProductCategory(category){
    try{
      const {data} = await $axios.put(`segment-product-category/${category.id}`,category);
      return data;
    }catch (e) {
      return e;
    }
  },
  async deleteSegmentProductCategory(category_id){
    try{
      const {data} = await $axios.delete(`segment-product-category/${category_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getSegmentProductsByCategory(category_id){
    try{
      const {data} = await $axios.get(`segment-products/${category_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getSegmentProduct(product_id){
    try{
      const {data} = await $axios.get(`segment-product/${product_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async postSegmentProduct(product){
    try{
      const {data} = await $axios.post(`segment-product`,product);
      return data;
    }catch (e) {
      return e;
    }
  },
  async putSegmentProduct(product){
    try{
      const {data} = await $axios.put(`segment-product/${product.id}`,product);
      return data;
    }catch (e) {
      return e;
    }
  },
  async deleteSegmentProduct(product_id){
    try{
      const {data} = await $axios.delete(`segment-product/${product_id}`);
      return data;
    }catch (e) {
      return e;
    }
  }
};
export default productSegmentService;
