import $axios from "@/plugins/axios"
const AgencyService = {
  async postRegister(user){
    try{
      const response = await $axios.post(`agency/create-owner`,user);
      return response.data;
    }catch (e) {
      return e.response.data;
    }
  },
  async getHotels(){
    try{
      const {data} = await $axios.get(`agency/get-hotels`);
      return data;
    }catch (e) {

      return e;
    }
  },
  async getUsers(){
    try{
      const {data} = await $axios.get(`agency/get-owners`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async loginAsOwner(user_id){
    try{
      const {data} = await $axios.get(`agency/login-as-owner/${user_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async verifyUser(user_id){
    try{
      const {data} = await $axios.get(`agency/verify-from-agency/${user_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async editOwner(user){
    try{
      const {data} = await $axios.put(`agency/edit-owner/${user.id}`,user);
      return data;
    }catch (e) {
      return e;
    }
  },
  async deleteOwner(user_id){
    try{
      const {data} = await $axios.delete(`agency/delete-owner/${user_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async toggleBlockUser(user_id){
    try{
      const {data} = await $axios.get(`agency/toggle-block/${user_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async editHotel(hotel){
    try{
      const {data} = await $axios.put(`agency/edit-hotel/${hotel.id}`,hotel);
      return data;
    }catch (e) {
      return e;
    }
  },
  async deleteHotel(hotel_id){
    try{
      const {data} = await $axios.delete(`agency/delete-hotel/${hotel_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async downloadHotelData(hotel_id){
    try{
      const {data} = await $axios.get(`agency/download-hotel-data/${hotel_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async uploadHotelData(payload){
    try{
      const formData = new FormData();
      formData.append("hotel_id", `${payload.hotel_id}`);
      formData.append("file", payload.file, payload.file.name);
      const {data} = await $axios.post(`agency/upload-hotel-data`,formData);
      return data;
    }catch (e) {
      return e;
    }
  },
  async cloneHotel(payload){
    try{
      const {data} = await $axios.get(`agency/clone-hotel/${payload.hotel_id}/${payload.user_id}`);
      return data[0]?.id?data[0]:data;
    }catch (e) {
      return e;
    }
  },
  async getPayments(payload){
    try{
      const {data} = await $axios.get(`agency/get-subscriptions`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getAgencySlots(user_id){
    try{
      const {data} = await $axios.get(`agency/slots/${user_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async createHotel(hotel){
    try{
      const {data} = await $axios.post(`agency/create-hotel`,hotel);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getSlotsQuantity(user_id){
    try{
      const {data} = await $axios.get(`agency/slots-quantity/${user_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getAgencySettings(user_id){
    try{
      const {data} = await $axios.get(`agency/partner-settings/${user_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async postAgencySettings(payload){
    try{
      const {data} = await $axios.post(`agency/partner-setting`,payload);
      return data
    }catch (e) {
      return e;
    }
  },
  async putAgencySettings(payload){
    try{
      const {data} = await $axios.put(`agency/partner-setting/${payload.id}`,payload);
      return data;
    }catch (e) {
      return e;
    }
  },
  async deleteAgencySettings(settings_id){
    try{
      const {data} =  await $axios.delete(`agency/partner-setting/${settings_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getSettingsByUser(hotel_id){
    try{
      const {data} =  await $axios.get(`partner-settings/${hotel_id}`)
      return data;
    }catch (e) {
      return e;
    }
  },
  async getAuthSettings(domain){
    try{
      const {data} = await $axios.get(`agency/get-authorisation/${domain}`);
      return data;
    }catch (e) {
      return e;
    }
  }

};
export default AgencyService;
