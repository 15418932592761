export default {
    namespaced: true,
    state:{
        volume:1,
        likedStations:[],
        stationsData:[],
        selectedRadio:null,
        selectedGroupID:null,
        soundID:null,
        sound:null
    },
    mutations:{
        setStationsData(state,list){
          state.stationsData = list;
        },
        setSound(state,sound){
          state.sound = sound;
        },
        setSoundID(state,soundID){
          state.soundID = soundID;
        },
        volumeSlider(state,value){
            state.volume = value
        },
        saveLikedStation(state,station){
            state.likedStations.push(station);
        },
        removeLikedStation(state,station){
            const candidate = state.likedStations.find(x => x.id === station.id && x.group_id === station.group_id);
            if(candidate){
                const candidateIndex = state.likedStations.indexOf(candidate);
                if(candidateIndex >= 0){
                    state.likedStations.splice(candidateIndex,1);
                }
            }
        },
        addDefaultGroup(state,payload){
            const defaultStationGroup = {
                id:0,
                title:"default group title",
                stations:[]
            }
            defaultStationGroup.id = state.stationsData.length?state.stationsData[state.stationsData.length-1].id+1:0
            state.stationsData.push(defaultStationGroup);
            payload.content = JSON.parse(JSON.stringify(state.stationsData));
        },
        deleteGroup(state,payload){
            const candidate = state.stationsData.find(x => x.id === payload.stationGroupID);
            if(candidate){
                const candidateIndex = state.stationsData.indexOf(candidate);
                if(candidateIndex >= 0){
                    state.stationsData.splice(candidateIndex,1);
                }
            }
            payload.content = JSON.parse(JSON.stringify(state.stationsData));
        },
        addStationToGroup(state,payload){
            const defaultStation =
            {
                id: 0,
                title: "default title",
                src: "",
                playing: false,
                imageSrc: null,
                is_active:true,
                description: "default description",
                website: "",
                group_id:payload.stationGroupID
            };
            const candidateGroup = state.stationsData.find(x => x.id === payload.stationGroupID);
            if(candidateGroup){
                const candidateGroupIndex = state.stationsData.indexOf(candidateGroup);
                if(candidateGroupIndex >= 0){
                    defaultStation.id = state.stationsData[candidateGroupIndex].stations.length?state.stationsData[candidateGroupIndex].stations[state.stationsData[candidateGroupIndex].stations.length-1].id+1:0
                    state.stationsData[candidateGroupIndex].stations.push(defaultStation);
                }
            }
            payload.content = JSON.parse(JSON.stringify(state.stationsData));
        },
        setSelectedStation(state,payload){
            state.selectedGroupID = payload.groupID;
            state.selectedRadio = payload.station;
        },
        editStationFromGroup(state,payload){
            const candidateGroup = state.stationsData.find(x => x.id === payload.groupID);
            if(candidateGroup) {
                const candidateGroupIndex = state.stationsData.indexOf(candidateGroup);
                if(candidateGroupIndex >= 0){
                    const candidateStation = state.stationsData[candidateGroupIndex].stations.find(x => x.id === payload.station.id);
                    if(candidateStation){
                        const candidateStationIndex = state.stationsData[candidateGroupIndex].stations.indexOf(candidateStation);
                        if(candidateStationIndex >= 0){
                            state.stationsData[candidateGroupIndex].stations.splice(candidateStationIndex,1,payload.station);
                        }
                    }
                }
            }
            payload.content = JSON.parse(JSON.stringify(state.stationsData));
        },
        deleteStationFromGroup(state,payload){
            const candidateGroup = state.stationsData.find(x => x.id === payload.groupID);
            if(candidateGroup) {
                const candidateGroupIndex = state.stationsData.indexOf(candidateGroup);
                if(candidateGroupIndex >= 0){
                    const candidateStation = state.stationsData[candidateGroupIndex].stations.find(x => x.id === payload.stationID);
                    if(candidateStation){
                        const candidateStationIndex = state.stationsData[candidateGroupIndex].stations.indexOf(candidateStation);
                        if(candidateStationIndex >= 0){
                            state.stationsData[candidateGroupIndex].stations.splice(candidateStationIndex,1);
                        }
                    }
                }
            }
            payload.content = JSON.parse(JSON.stringify(state.stationsData));
        },
    },
    actions:{

    },
    getters:{

    }
}