import ApiService from "@/services/api.services.js"
import EventService from "../services/event.service";
import moment from "moment";
const timeToMs = (time) => {
    const arr = time.split(':');
    return (+arr[0]*3600000) + (+arr[1]*60000);
}
export default {
    namespaced: true,
    state: {
        eventList: [],
        eventID:null,
        eventCategories:[],
        eventCategory:null,
        page:"list"
    },
    mutations: {
        setEventCategories(state,list){
          state.eventCategories = list;
        },
        setEventCategory(state,category){
          state.eventCategory =  category;
        },
        addEventCategory(state,category){
          state.eventCategories.push(category);
        },
        editEventCategory(state,category){
          const candidate = state.eventCategories.find(x => x.id === category.id);
          if(candidate){
              const candidateIndex = state.eventCategories.indexOf(candidate);
              state.eventCategories.splice(candidateIndex,1,category);
          }
        },
        deleteEventCategory(state,category){
            const candidate = state.eventCategories.find(x => x.id === category.id);
            if(candidate){
                const candidateIndex = state.eventCategories.indexOf(candidate);
                state.eventCategories.splice(candidateIndex,1)
            }
        },
        setEventList(state, list) {
            state.eventList = list;
        },
        addNewEventToList(state, event) {
            const candidate = state.eventCategories.find(category => category.id === event.event_category_id);
            if(candidate){
                const candidateIndex = state.eventCategories.indexOf(candidate);
                if(candidateIndex>=0){
                    state.eventCategories[candidateIndex].events.push(event);
                }
            }
        },
        deleteEventFromList(state,event){
            let candidateCategory;
            if(event.prevGroup){
                candidateCategory = state.eventCategories.find(category => category.id === event.prevGroup);
            }else{
                candidateCategory = state.eventCategories.find(category => category.id === event.event_category_id);
            }
            if(candidateCategory){
                const candidateCategoryIndex = state.eventCategories.indexOf(candidateCategory);
                const candidate = state.eventCategories[candidateCategoryIndex].events.find(x => x.id === event.id);
                if(candidate){
                    state.eventCategories[candidateCategoryIndex].events.splice(state.eventCategories[candidateCategoryIndex].events.indexOf(candidate),1);
                }
            }
        },
        changeEvent(state,event){
            const candidateCategory = state.eventCategories.find(category => category.id === event.event_category_id);
            if(candidateCategory){
                const candidateCategoryIndex = state.eventCategories.indexOf(candidateCategory);
                const candidate = state.eventCategories[candidateCategoryIndex].events.find(x => x.id === event.id);
                if(candidate){
                    state.eventCategories[candidateCategoryIndex].events.splice(state.eventCategories[candidateCategoryIndex].events.indexOf(candidate),1,event);
                }
            }
        },
        setEventID(state,id){
            state.eventID = id;
        }
    },
    getters:{
        sortedEvents(state){
           return state.eventList.sort((a,b) => (+moment(a.date).format('x')+timeToMs(a.time)) - (+moment(b.date).format('x')+timeToMs(a.time))).filter(event => event.show_event)
        }
    },
    actions: {
        async getEventListByHotel({ commit }, hotel_id) {
            try{
                const { data } = await ApiService.get(`/events/${hotel_id}`);
                commit('setEventList', data);
                commit('setEventID',hotel_id);
            }catch(e){
                return e;
            }
        },
        async createNewEvent({ commit }, event) {
            try{
            const { data } = await ApiService.post(`/event`, event);
            commit('addNewEventToList', data);
            }catch(e){
                return e;
            }
        },
        async editEvent({commit},event){
            try{
                const {data} = await ApiService.put(`/event/${event.id}`,event);
                if(event.prevGroup !== event.event_category_id){
                    commit('deleteEventFromList',event);
                    commit('addNewEventToList',data);
                }else{
                    commit('changeEvent',data);
                }
            }catch(e){
                return e;
            }
        },
        async deleteEvent({commit},eventID){
            try{
                const {data} = await ApiService.delete(`/event/${eventID}`);
                commit('deleteEventFromList',data);
            }catch(e){
                return e;
            }
        },
        async getEventCategories({commit},hotel_id){
            try{
                const data = await EventService.getEventCategories(hotel_id);
                commit('setEventCategories',data);
            }catch (e) {
                return e;
            }
        },
        async addEventCategory({commit},category){
            try{
                const data = await EventService.addEventCategory(category);
                commit('addEventCategory',data);
            }catch (e) {
                return e;
            }
        },
        async editEventCategory({commit},category){
            try{
                const data = await EventService.editEventCategory(category)
                commit('editEventCategory',data);
            }catch (e) {
                return e;
            }
        },
        async deleteEventCategory({commit},category_id){
            try{
                const data = await EventService.deleteEventCategory(category_id);
                commit('deleteEventCategory',data);
            }catch (e) {
                return e;
            }
        }
    },
}
