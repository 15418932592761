import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        statistics: [],
        text_answers: 0,
        text_question: null,
        answers: [],
    },
    mutations: {
        setList(state, data) {
            let statistics = [];
            
            Object.keys(data.polls).forEach(key => {
                switch (key) {
                    case '0':
                        statistics.push(data.polls[key]);
                        break;
                    case '1':
                        statistics.push(data.polls[key]);
                        break;
                    case '2':
                        statistics.push(data.polls[key]);
                        break;
                    case 'text_answers':
                        state.text_answers = data.polls.text_answers;
                        break;
                    case 'text_question':
                        state.text_question = data.polls.text_question;
                        break;
                    default:
                        break;
                }
            });
            
            state.statistics = statistics;
            state.answers = data.polls_answers;
        },
    },
    actions: {
        getList({state, commit}, data) {
            // console.log(data)
            return new Promise((resolve, reject) => {
                Vue.prototype.$axios
                    .get('get-polls-one', {
                        params: {
                            hotel_id: data.hotel_id,
                            date_start: data.date_start,
                            date_end: data.date_end,
                        },
                    })
                    .then(response => {
                        for(let i = 0;i<response.data.polls_answers.length;i++) {
                            for(let index = 0;index<response.data.polls_answers[i].answer_4.length;index++){
                                response.data.polls_answers[i].answer_4[index].id = response.data.polls_answers[i].id;
                            }
                        }
                        commit('setList', response.data);
                        resolve(response);
                    })
                    .catch(error => {
                        state.statistics = [];
                        state.text_answers = 0;
                        state.text_question = null;
                        state.answers = [];
                        reject(error.response)
                    });
            });
        },
    },
}