import CatalogService from "../services/catalog.service";

export default {
  namespaced:true,
  state:{
    catalogCategories:[],
    catalogCategory:null,
    catalogs:[],
    catalog:null,
    error:false,
    error_message:""
  },
  getters:{

  },
  actions:{
    async getCatalogCategories({commit},payload){
      try{
        const data = await CatalogService.getCatalogCategories(payload);
        commit('setCatalogCategories',data);
      }catch(e){
        commit('setError',e);
      }
    },
    async getCatalogCategory({commit},catalog_category_id){
      try{
        const data = await CatalogService.getCatalogCategory(catalog_category_id);
        commit('setCatalogCategory',data);
      }catch (e) {
        commit('setError',e);
      }
    },
    async postCatalogCategory({commit},catalog_category){
      try{
        const data = await CatalogService.postCatalogCategory(catalog_category);
        commit('postCatalogCategory',data);
      }catch(e){
        commit('setError',e);
      }
    },
    async putCatalogCategory({commit},catalog_category){
      try{
        const data = await CatalogService.putCatalogCategory(catalog_category);
        commit('putCatalogCategory',data);
      }catch (e) {
        commit('setError',e);
      }
    },
    async deleteCatalogCategory({commit},catalog_category_id){
      try{
        const data = await CatalogService.deleteCatalogCategory(catalog_category_id);
        commit('deleteCatalogCategory',data);
      }catch(e){
        commit('setError',e);
      }
    },
    async getCatalogs({commit},catalog_category_id){
      try{
        const data = await CatalogService.getCatalogs(catalog_category_id);
        commit('setCatalogs',data);
      }catch (e) {
        commit('setError',e);
      }
    },
    async getCatalog({commit},catalog_id){
      try{
        const data = await CatalogService.getCatalog(catalog_id);
        commit('setCatalog',data);
      }catch(e){
        commit('setError',e);
      }
    },
    async postCatalog({commit},catalog){
      try{
        const data = await CatalogService.postCatalog(catalog);
        commit('postCatalog',data);
      }catch(e){
        commit('setError',e);
      }
    },
    async putCatalog({commit},catalog){
      try{
        const data = await CatalogService.putCatalog(catalog);
        commit('putCatalog',data);
      }catch(e){
        commit('setError',e);
      }
    },
    async deleteCatalog({commit},catalog_id){
      try{
        const data = await CatalogService.deleteCatalog(catalog_id);
        commit('deleteCatalog',data);
      }catch(e){
        commit('setError',e);
      }
    }
  },
  mutations:{
    setError(state,error){
      state.error = true;
      state.error_message = error.message;
    },
    setCatalogCategories(state,catalogCategories){
      state.catalogCategories = catalogCategories;
    },
    setCatalogCategory(state,catalogCategory){
      state.catalogCategory = catalogCategory;
    },
    postCatalogCategory(state,catalogCategory){
      state.catalogCategories.push(catalogCategory);
      state.catalogCategory = catalogCategory;
    },
    putCatalogCategory(state,catalogCategory){
      const candidate = state.catalogCategories.find(category => category.id === catalogCategory.id);
      if(candidate){
        const candidateIndex = state.catalogCategories.indexOf(candidate);
        if(candidateIndex>=0){
          state.catalogCategories.splice(candidateIndex,1,catalogCategory);
        }
      }
    },
    deleteCatalogCategory(state,catalogCategory){
      const candidate = state.catalogCategories.find(category => category.id === catalogCategory.id);
      if(candidate){
        const candidateIndex = state.catalogCategories.indexOf(candidate);
        if(candidateIndex>=0){
          state.catalogCategories.splice(candidateIndex,1);
        }
      }
    },
    setCatalogs(state,catalogCategory){
      state.catalogCategory = catalogCategory;
    },
    setCatalog(state,catalog){
      state.catalog = catalog;
    },
    postCatalog(state,catalog){
      state.catalogs.push(catalog);
      state.catalog = catalog;
    },
    putCatalog(state,catalog){
      const candidate = state.catalogs.find(item => item.id === catalog.id);
      if(candidate){
        const candidateIndex = state.catalogs.indexOf(candidate);
        if(candidateIndex>=0){
          state.catalogs.splice(candidateIndex,1,catalog);
          state.catalog = catalog;
        }
      }
    },
    deleteCatalog(state,catalog){
      const candidate = state.catalogs.find(item => item.id === catalog.id);
      if(candidate){
        const candidateIndex = state.catalogs.indexOf(candidate);
        if(candidateIndex>=0){
          state.catalogs.splice(candidateIndex,1);
          state.catalog = null;
        }
      }
    }
  }
}
