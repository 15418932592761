import ApiService from "./api.services";

const IconService = {
  /**
   * 
    * @param {Object} params
    * @param {string} params.name - Название иконки
    * @param {File} params.file - Файл
   
    * @returns Promise
  */
  postIcon: async function (params) {
    try {
      const response = await ApiService.post('icons', params);

      return response.data;
    } catch (e) {
      return e;
    }
  },
  /**
   * 
    * @param {Object} params
    * @param {string} params.url - url иконки
    
    * @returns Promise
  */
  deleteIcon: async function (params) {
    try {
      const response = await ApiService.delete('icons', params);

      return response.data;
    } catch (e) {
      return e;
    }
  },
  getIcons: async function () {
    try {
      const response = await ApiService.get('icons');
      return response.data.filter(x => x.file_name !== '.' && x.file_name !== '..' && x.file_name !== '.gitignore');
    } catch (e) {
      return e;
    }
  }
}

export default IconService;