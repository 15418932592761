import $axios from "../plugins/axios"

const TrailsService = {

    async getTrails(payload){
        try{
            const {data} = await $axios.get(`routes/${payload.hotel_id}/${payload.menu_id}`);
            return data;
        }catch (e){
            return e;
        }
    },
    async getTrail(trail_id){
        try{
            const {data} = await $axios.get(`route/${trail_id}`);
            return data;
        }catch(e){
            return e;
        }
    },
    async getTrailPoints(trail_id){
        try{
            const {data} =  await $axios.get(`route-points/${trail_id}`);
            return data;
        }catch(e){
            return e;
        }
    },
    async getTrailPoint(trail_point_id){
        try{
            const {data} = await $axios.get(`route-point/${trail_point_id}`);
            return data;
        }catch(e){
            return e;
        }
    },
    async createTrail(payload){
        try{
            const {data} = await $axios.post(`route`,payload);
            return data;
        }catch(e){
            return e;
        }
    },
    async createTrailPoint(payload){
        try{
            const {data} = await $axios.post(`route-point`,payload);
            return data;
        }catch(e){
            return e;
        }
    },
    async editTrail(payload){
        try{
            const {data} = await $axios.put(`route/${payload.id}`,payload);
            return data;
        }catch(e){
            return e;
        }
    },
    async editTrailPoint(payload){
        try{
            const {data} = await $axios.put(`route-point/${payload.id}`,payload);
            return data;
        }catch(e){
            return e;
        }
    },
    async deleteTrail(trail_id){
        try{
            const {data} = await $axios.delete(`route/${trail_id}`);
            return data;
        }catch(e){
            return e;
        }
    },
    async deleteTrailPoint(trail_point_id){
        try{
            const {data} = await $axios.delete(`route-point/${trail_point_id}`);
            return data;
        }catch(e){
            return e;
        }
    }

};
export default TrailsService;