import ApiService from "./api.services";

const adminLogin = 'admin-login';

const AuthService = {
   /**
   *
    * @param {Object} params
    * @param {string} params.admin_token - токен, пришедший из админки

    * @returns Promise
  */
  postAdminLogin: async (params) => {
    try {
      const response = await ApiService.post(adminLogin, params);
      return response.data;
    } catch (e) {
      return e;
    }
  },

}

export default AuthService;
