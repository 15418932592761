import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        item: {
            name: null,
        },
        list: []
    },
    mutations: {
        setList(state, list) {
            state.list = list;
        },
    },
    actions: {
        create({commit}, sticker) {
            let formData = new FormData();
            
            Object.keys(sticker).forEach(key => {
                if (sticker[key]) {
                    formData.append(key, sticker[key]);
                }
            }) 

            return new Promise((resolve, reject) => {
                Vue.prototype.$axios
                    .post('create-sticker', formData)
                    .then(response => {
                        // console.log(response)
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error.response)
                    });
            });
        },
        getList({commit}) {
            return new Promise((resolve, reject) => {
                Vue.prototype.$axios
                    .get('get-stickers')
                    .then(response => {
                        let stickers = [];
                        response.data.data.forEach(element => {
                            element.stickers.forEach(sticker => {
                                stickers.push(sticker);
                            })
                        });
                        commit('setList', stickers)
                        resolve(response.data.data);
                    })
                    .catch(error => {
                        reject(error.response)
                    });
            });
        }
    },
}