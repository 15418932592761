<template>
  <div  style="display: flex;justify-content: space-between;align-items: center;width: 100%;padding: 15px;box-shadow: 0 0 0 1px rgba(68,92,116,0.02),0 2px 8px rgba(57,76,96,0.15);">
    <div @click="back">
      <v-icon>mdi-chevron-left</v-icon>
    </div>
    <div style="text-transform: capitalize;display: flex;align-items:center;justify-content: center;">
      <span style="font-weight: bold">{{$route.name}}</span>
      <div v-if="$route.name === 'Constructor'" style="margin-left: 15px;">
            {{ currentName }}
            <div style="margin-left: 0" v-if="objects.item.tariff_id === 1" class="tariff_obj">free</div>
            <div style="margin-left: 0" v-if="objects.item.tariff_id === 2" class="tariff_obj">
              standart
            </div>
            <div style="margin-left: 0" v-if="objects.item.tariff_id === 3" class="tariff_obj">pro</div> id: {{ objects.item.id }}
    </div>
    </div>
    <div>
      <v-icon>mdi-dots-horizontal</v-icon>
    </div>
    <v-bottom-sheet v-model="sheet" scrollable>
      <v-list
          style="border-top-right-radius: 5px; border-top-left-radius: 5px"
      >
        <v-list-item
            @click="changePage('start',0)"
            class="header__tab"
            :class="{ activeTab: $store.state.selectedPage === 'start' }"
        >
          Start
        </v-list-item>
        <v-list-item
            @click="changePageBranding()"
            class="header__tab"
            :class="{ activeTab: $store.state.selectedPage === 'branding' }"
        >
          {{ $t('constructor["Branding"]') }}
        </v-list-item>
        <v-list-item
            @click="changePage('home',2)"
            class="header__tab"
            :class="{ activeTab: $store.state.selectedPage === 'home' && $store.state.tab === 2 }"
        >
          {{ $t('constructor["Menu"]') }}
        </v-list-item>
        <v-list-item
            @click="changePage('reviews',3)"
            class="header__tab"
            :class="{ activeTab: $store.state.selectedPage === 'reviews' }"
        >
          {{ $t('constructor["Reviews"]') }}
        </v-list-item>
        <v-list-item
            @click="changePage('order',4)"
            class="header__tab"
            :class="{ activeTab: $store.state.selectedPage === 'order' }"
        >
          Order
        </v-list-item>
        <v-list-item
            @click="changePage('advanced',5)"
            class="header__tab"
            :class="{ activeTab: $store.state.selectedPage === 'advanced' }"
        >
          {{ $t('constructor["Advanced"]') }}
        </v-list-item>
        <v-list-item
            @click="changePage('no_preview',6)"
            class="header__tab"
            :class="{ activeTab: $store.state.selectedPage === 'no_preview' && $store.state.tab === 6 }"
        >
          Upsell
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
  export default {
    name: "MobileHeader",
    data(){
      return{
        sheet:false
      }
    },
    mounted() {
      console.log(this.$router);
    },
    computed:{
      ...mapState(['landing']),
      ...mapState(["objects"]),
      currentName() {
        const candidate = this.objects.list.find(
            (object) => object.id === this.objects.item.id
        );
        if (candidate) {
          return candidate.name;
        } else {
          return "";
        }
      },
    },
    methods:{
      ...mapMutations("menu", ["setSelectedMenuItem"]),
      openConstructorMenu(){
        if(this.$route.name === 'Constructor'){
          this.sheet = true;
        }
      },
      changePageBranding() {
        if (this.landing.branding.active_tab === 6) {
          this.changePage("lining");
        } else if (this.landing.branding.active_tab === 9) {
          this.changePage("intro");
        } else {
          this.changePage("home");
        }
        this.$store.state.selectedPage = "branding";
        this.$store.state.tab = 1;
      },
      changePage(page,index) {
        this.$store.state.tab = index;
        this.$store.state.selectedPage = page;
        if (this.selectedMenuItem) {
          const candidate = this.$store.state.menu.list.find(
              (item) => item.id === this.selectedMenuItem.id
          );
          if (candidate) {
            const candidateIndex = this.$store.state.menu.list.indexOf(candidate);
            if (candidateIndex >= 0) {
              this.$store.state.menu.list.splice(
                  candidateIndex,
                  1,
                  JSON.parse(JSON.stringify(this.selectedMenuItem))
              );
            }
          }
        }
        this.setSelectedMenuItem(null);
        this.$store.commit("setPage", page);
        this.sheet = false;
      },
      back(){
        this.$router.go(-1);
      }
    }
  }
</script>

<style scoped>

</style>
