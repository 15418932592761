export default {
    namespaced: true,
    state:{
        title:"",
        location:"",
        page_404:null,
      install_settings:{
        is_active:1,
        title:{
          en:"App Name",
        },
        description:{
          en:"Region app"
        },
        button:{
          en:"Install"
        },
        image:"",
        hotel_id:""
      }
    },
    mutations:{
        setPage404(state,page){
          state.page_404 = page;
        },
        setTitle(state,title){
            state.title = title;
        },
        setLocation(state,location){
            state.location = location;
        }
    },
    getters:{

    },
    actions:{

    }
}
