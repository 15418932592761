import $axios from "../plugins/axios";

const ProductsService = {
  async getAllProductsFromBase(){
    const {data} = await $axios.get(`get-all-product-categories`);
    return data;
  },
  async editProductFromBase(product){
    try{
      const {data} = await $axios.put(`edit-product/${product.id}`,product)
    }catch (e){
      return e;
    }
  },
  async getCategoriesList(hotel_id, menu_id) {
    try {
      const response = await $axios.get(
        `${process.env.VUE_APP_API_URL}product-categories/${hotel_id}/${menu_id}`
      );
      return response.data;
    } catch (e) {
      return e;
    }
  },
  async createCategory(payload) {
    try {
      const response = await $axios.post(
        `${process.env.VUE_APP_API_URL}product-category`,
        payload
      );
      return response.data;
    } catch (e) {
      return e;
    }
  },
  async editCategory(payload) {
    try {
      const response = await $axios.put(
        `/product-category/${payload.id}`,
        payload
      );
      return response.data;
    } catch (e) {
      return e;
    }
  },
  async createSubCategory(payload) {
    try {
      const response = await $axios.post(
        `${process.env.VUE_APP_API_URL}product-sub-category`,
        payload
      );
      return response.data;
    } catch (e) {
      return e;
    }
  },
  async editSubCategory(payload) {
    try {
      const response = await $axios.put(
        `/product-sub-category/${payload.id}`,
        payload
      );
      return response.data;
    } catch (e) {
      return e;
    }
  },
  async createProduct(payload) {
    try {
      const response = await $axios.post(`/product`, payload);
      return response.data;
    } catch (e) {
      return e;
    }
  },
  async editProduct(payload) {
    try {
      const response = await $axios.put(`product/${payload.id}`, payload);
      return response.data;
    } catch (e) {
      return e;
    }
  },
  async getProduct(product_id){
    try{
      const {data} = await $axios.get(`product/${product_id}`);
      return data;
    }catch (e) {
      return e;
    }
  }
};
export default ProductsService;
