import realEstateService from "@/services/realEstate.service"
export default {
  namespaced:true,
  state:{
    realEstates:[],
    realEstate:null,
    error:false,
    error_message:"",
    localPage:"list",
    previewPage:"list",
  },
  getters:{
  },
  actions:{
    async getRealEstates({commit},payload){
      try{
        const data = await realEstateService.getRealEstates(payload);
        commit('setRealEstates',data);
      }catch(e){
        commit('setError',e);
      }
    },
    async getRealEstate({commit},id){
      try{
        const data = await realEstateService.getRealEstate(id);
        commit('setRealEstate',data);
      }catch(e){
        commit('setError',e);
      }
    },
    async postRealEstate({commit},realEstate){
      try{
        const data = await realEstateService.postRealEstate(realEstate);
        commit('addRealEstate',data);
      }catch (e) {
        commit('setError',e);
      }
    },
    async putRealEstate({commit},realEstate){
      try{
        const data = await realEstateService.putRealEstate(realEstate);
        commit('editRealEstate',data);
      }catch(e){
        commit('setError',e);
      }
    },
    async deleteRealEstate({commit},id){
      try{
        const data = await realEstateService.deleteRealEstate(id);
        commit('deleteRealEstate',data);
      }catch (e) {
        commit('setError',e);
      }
    }
  },
  mutations:{
    setPreviewPage(state,page){
      state.previewPage = page;
    },
    setLocalPage(state,page){
      state.localPage = page;
    },
    setError(state,error){
      state.error_message = error.message;
      state.error = true;
    },
    setRealEstates(state,realEstates){
      state.realEstates = realEstates;
    },
    setRealEstate(state,realEstate){
      state.realEstate = realEstate;
    },
    addRealEstate(state,realEstate){
      state.realEstates.push(realEstate);
    },
    editRealEstate(state,realEstate){
      const candidate = state.realEstates.find(item => item.id === realEstate.id);
      if(candidate){
        const candidateIndex = state.realEstates.indexOf(candidate);
        if(candidateIndex>=0){
          state.realEstates.splice(candidateIndex,1,realEstate)
        }
      }
    },
    deleteRealEstate(state,realEstate){
      const candidate = state.realEstates.find(item => item.id === realEstate.id);
      if(candidate){
        const candidateIndex = state.realEstates.indexOf(candidate);
        if(candidateIndex>=0){
          state.realEstates.splice(candidateIndex,1)
        }
      }
    }

  }
}
