<template>
<div style="overflow-y: scroll;">
  <slot/>
</div>
</template>

<script>
  export default {
    name: "MobileMain"
  }
</script>

<style scoped>

</style>
