import ApiService from "./api.services";

const banners = 'banners';

const BannerService = {
  /**
   * 
    * @param {Object} params
    * @param {number} params.lending_setting_id - ID настроек лендинга
    * @param {File} params.file - Файл
    * @param {number} params.banner_type_id - ID типа баннера, есть на /banner-types
    * @param {string} params.data - Данные баннера, например ссылка (опционально)
   
    * @returns Promise
  */
  postBanner: async function (params) {
    try {
      const response = await ApiService.post(banners, params);

      return response.data;
    } catch (e) {
      return e;
    }
  },
  /**
   * 
    * @param {Object} params
    * @param {number} params.lending_setting_id - ID настроек лендинга
    * @param {number} params.banner_type_id - ID типа баннера, есть на /banner-types
    
    * @returns Promise
  */
  deleteBanner: async function (params) {
    try {
      const response = await ApiService.delete(banners, params);

      return response.data;
    } catch (e) {
      return e;
    }
  },
  /**
   * 
    * @param {Object} params
    * @param {number} params.lending_setting_id - ID настроек лендинга
    
    * @returns Promise
  */
  getBanners: async function (params) {
    try {
      const query = `?lending_setting_id=${params.lending_setting_id}`;
      const response = await ApiService.get(banners + `${query}`);

      return response.data;
    } catch (e) {
      return e;
    }
  },
  getBannerTypes: async function () {
    try {
      const response = await ApiService.get('banner-types');

      return response.data;
    } catch (e) {
      return e;
    }
  },

  async getStatisticsByPeriod(payload){
    try{
        const response = await ApiService.get(`get-banner-statistic?hotel_id=${payload.id}&from=${payload.start}&to=${payload.end}`);
        return response.data;
    }  catch (e) {
        return e;
    }
  },
  async addStatistics(payload){
      try{
          const response = await ApiService.put(`update-banner-statistic`,payload);
          return response.data;
      }catch(e){
          return e;
      }
  }
};

export default BannerService;